import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import { useDebounce, useQuery } from "lib/hooks";

export default function UserSelect({ value, onChange, disabled }) {
  const [q, setQ] = useState("");
  const { fetchData, data } = useQuery({ uri: `/user/filter`, manual: true, params: { q } });

  const onSearch = (val) => fetchData(`/user/filter`, { q: val });

  useDebounce(onSearch, 1200, q);

  const options =
    data?.users?.map((user) => ({
      _id: user._id,
      username: user.username,
      profile_image: user.profile_image,
      display_name: user.display_name,
    })) || [];

  if (disabled)
    return (
      <MDBox display="flex" alignItems="center">
        <MDBox mr={2}>
          <MDAvatar src={value?.profile_image} alt="something here" shadow="md" />
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <MDTypography variant="button" fontWeight="medium">
            {value?.display_name}
          </MDTypography>
          <MDTypography variant="caption" color="text">
            {value?.username}
          </MDTypography>
        </MDBox>
      </MDBox>
    );

  return (
    <Autocomplete
      value={value}
      id="highlights-demo"
      options={options}
      getOptionLabel={(option) => option.display_name || option.username}
      onChange={(_, option) => onChange(option)}
      renderInput={(params) => (
        <MDInput
          {...params}
          fullWidth
          value={q || ""}
          label="User"
          variant="standard"
          placeholder="user search"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            setQ(e.target.value);
            onSearch(e.target.value);
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <MDBox component="li" display="flex" alignItems="center" py={1} mb={1}>
            <MDBox mr={2}>
              <MDAvatar src={option.profile_image} alt="something here" shadow="md" />
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <MDTypography variant="button" fontWeight="medium">
                {option?.display_name}
              </MDTypography>
              <MDTypography variant="caption" color="text">
                {option?.username}
              </MDTypography>
            </MDBox>
          </MDBox>
        </li>
      )}
    />
  );
}
