/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

import DataTable from "examples/Tables/DataTable";

// Data
import React from "react";
import dayjs from "dayjs";

import { useQuery } from "lib/hooks";

import Loader from "components/Loader";
import DefaultCell from "examples/Tables/DefaultCell";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";

function NFTCollections() {
  const { data, loading } = useQuery({ uri: "/mongolnft/nft-collections" });
  console.log(data);
  return (
    <>
      <Card>
        {loading ? (
          <Loader />
        ) : (
          <DataTable
            entriesPerPage={false}
            table={{
              columns: [
                {
                  Header: "Cover",
                  accessor: "image",
                  Cell: ({ value, row }) => (
                    <MDAvatar
                      size="xl"
                      src={value}
                      variant="rounded"
                      alt={row?.original?.name || "ticketx"}
                    />
                  ),
                },
                {
                  Header: "Name",
                  accessor: "name",
                  Cell: ({ value }) => <DefaultCell value={value} />,
                },
                {
                  Header: "Description",
                  accessor: "description",
                  Cell: ({ value }) => <DefaultCell value={value} />,
                },
                {
                  Header: "Is locked",
                  accessor: "isLocked",
                  Cell: ({ value }) => <DefaultCell value={`${value}`} />,
                },
                {
                  Header: "Is hide",
                  accessor: "is_hide",
                  Cell: ({ value }) => <DefaultCell value={`${value}`} />,
                },
                {
                  Header: "Start date",
                  accessor: "startDate",
                  Cell: ({ value }) => (
                    <DefaultCell value={dayjs(value).format("YYYY-MM-DD HH:mm")} />
                  ),
                },
                {
                  sorted: "none",
                  accessor: "_id",
                  Header: "Action",
                  Cell: ({ value }) => (
                    <MDButton
                      component={Link}
                      color="secondary"
                      variant="gradient"
                      to={`/nft-collections/${value}`}
                    >
                      View templates
                    </MDButton>
                  ),
                },
              ],
              rows: data || [],
            }}
          />
        )}
      </Card>
    </>
  );
}

export default NFTCollections;
