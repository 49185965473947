import React from "react";
import { InputLabel, MenuItem } from "@mui/material";
import MDBox from "./MDBox";
import FormItem from "./FormItem";
import Select from "./Select";
import NumberInput from "./NumberInput";

function MoneyInput({ prefix = "sellPrice" }) {
  return (
    <MDBox display="flex" alignItems="center" gap={1}>
      <div>
        <FormItem name={[prefix, "amount"]} rules={[{ required: true }]}>
          <NumberInput label="Price" placeholder="200" />
        </FormItem>
      </div>
      <div>
        <InputLabel>Currency</InputLabel>
        <FormItem name={[prefix, "currency"]} rules={[{ required: true }]}>
          <Select fullWidth variant="standard" size="medium" sx={{ minHeight: "29px" }}>
            <MenuItem value="mnt">MNT</MenuItem>
            <MenuItem value="usd">USD</MenuItem>
          </Select>
        </FormItem>
      </div>
    </MDBox>
  );
}

export default MoneyInput;
