import React, { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import { getKeyFromName } from "lib/utils/merchandise";
import { formatMoney } from "lib/utils/helper";
import RenderOption from "./RenderOption";
import AttributeModal from "./AttributeModal";
import ItemModal from "./ItemModal";
import DeleteModal from "./DeleteModal";
import SellMerchItemModal from "./SellMerchItemModal";

function Variants({ data, id, refetch }) {
  const [activeAttr, setActiveAttr] = useState(null);
  const [remove, setRemove] = useState(null);
  const [removeAttr, setRemoveAttr] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [sellItem, setSellItem] = useState(null);

  return (
    <Card>
      <AttributeModal
        templateId={id}
        attr={activeAttr}
        refetch={refetch}
        open={!!activeAttr}
        onClose={() => setActiveAttr(null)}
      />
      <ItemModal
        attrs={data?.attrs}
        item={activeItem}
        refetch={refetch}
        open={!!activeItem}
        merchTemplateId={id}
        onClose={() => setActiveItem(null)}
      />
      <SellMerchItemModal
        open={!!sellItem}
        item={sellItem}
        onClose={() => setSellItem(null)}
        onSuccess={refetch}
      />
      <DeleteModal
        open={!!remove || !!removeAttr}
        uri={
          removeAttr
            ? `/merch-template/${id}/attribute/${removeAttr?.name_en}`
            : `/merch-template/merch-item/${remove?._id}`
        }
        onSuccess={() => {
          refetch();
          setRemoveAttr(null);
          setRemove(null);
        }}
        onClose={() => {
          setRemove(null);
          setRemoveAttr(null);
        }}
      >
        {remove && (
          <MDBox flex="1">
            <MDBox display="flex" flexWrap="wrap" gap={1} alignItems="center">
              {Object.entries(remove?.attrs || {})?.map(([key, value]) => (
                <RenderOption value={value} isColor={getKeyFromName(key) === "color"} />
              ))}
            </MDBox>
            <MDTypography variant="h6">
              price:
              <strong>{formatMoney(remove?.sellPrice?.amount, remove?.sellPrice?.currency)}</strong>
              , total: <strong>{remove?.qty}</strong>, available: <strong>{remove?.avail}</strong>
            </MDTypography>
          </MDBox>
        )}
      </DeleteModal>
      <MDBox p={3}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <MDBox>
              <MDTypography
                variant="h6"
                fontWeight="medium"
                textTransform="capitalize"
                color="white"
                mb={2}
              >
                Items
              </MDTypography>
              <Card>
                <MDBox p={3}>
                  {data?.items?.map((item, idx) => (
                    <MDBox
                      key={idx}
                      display="flex"
                      alignItems="center"
                      width="100%"
                      gap={1}
                      mb={2}
                      p={1}
                      borderRadius={4}
                      border="1px solid"
                    >
                      <MDBox flex="1">
                        <MDBox display="flex" flexWrap="wrap" gap={1} alignItems="center">
                          {Object.entries(item.attrs)?.map(([key, value]) => (
                            <RenderOption value={value} isColor={getKeyFromName(key) === "color"} />
                          ))}
                        </MDBox>
                        <MDTypography variant="h6">
                          price:
                          <strong>
                            {formatMoney(item.sellPrice.amount, item.sellPrice.currency)}
                          </strong>
                          , available: <strong>{item.avail}</strong>, on hold:{" "}
                          <strong>{item?.on_hold}</strong>
                        </MDTypography>
                      </MDBox>
                      <MDButton
                        iconOnly
                        color="success"
                        variant="contained"
                        onClick={() => setSellItem(item)}
                      >
                        <Icon>paid</Icon>
                      </MDButton>
                      <MDButton
                        iconOnly
                        color="info"
                        variant="contained"
                        onClick={() => setActiveItem(item)}
                      >
                        <Icon>edit</Icon>
                      </MDButton>
                      <MDButton
                        iconOnly
                        color="warning"
                        variant="contained"
                        onClick={() => setRemove(item)}
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                    </MDBox>
                  ))}
                  <MDBox display="flex" justifyContent="flex-end">
                    <MDButton onClick={() => setActiveItem({ merchTemplateId: id })}>
                      add item
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
          <Grid item md={6}>
            <MDBox>
              <MDTypography
                variant="h6"
                fontWeight="medium"
                textTransform="capitalize"
                color="white"
                mb={2}
              >
                Attributes
              </MDTypography>
              <Card>
                <MDBox p={3}>
                  {data?.attrs?.map((attr, idx) => (
                    <MDBox
                      key={idx}
                      display="flex"
                      alignItems="center"
                      width="100%"
                      gap={1}
                      mb={2}
                      p={1}
                      borderRadius={4}
                      border="1px solid"
                    >
                      <MDBox flex="1">
                        <MDTypography variant="h6">
                          {attr.name} ( {getKeyFromName(attr.name_en)} )
                        </MDTypography>
                        <MDBox display="flex" flexWrap="wrap" gap={1}>
                          {attr.options?.map((option) => (
                            <RenderOption
                              value={option.value}
                              isColor={getKeyFromName(attr.name_en) === "color"}
                            />
                          ))}
                        </MDBox>
                      </MDBox>
                      <MDButton
                        iconOnly
                        color="info"
                        variant="contained"
                        onClick={() => setActiveAttr(attr)}
                      >
                        <Icon>edit</Icon>
                      </MDButton>
                      <MDButton
                        iconOnly
                        color="warning"
                        variant="contained"
                        onClick={() => setRemoveAttr(attr)}
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                    </MDBox>
                  ))}
                  <MDBox display="flex" justifyContent="flex-end">
                    <MDButton
                      onClick={() =>
                        setActiveAttr({ name: "", name_en: "", options: [], isNew: true })
                      }
                    >
                      add attribute
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4} display="flex" justifyContent="flex-end">
          <MDButton variant="outlined" size="large" color="success" onClick={() => refetch()}>
            Refresh
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Variants;
