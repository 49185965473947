import { useCallback, useEffect, useState } from "react";

import axios from "../axios";

export const useQuery = ({ uri, params, manual, haveVersion = true }) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState();

  const fetchData = useCallback((path = uri, reqParams) => {
    setLoading(true);
    return axios
      .get(haveVersion ? `/v1${path}` : path, { params: reqParams })
      .then((res) => {
        setLoading(false);
        setResponse(res);
        return res;
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
        return e;
      });
  }, []);

  useEffect(() => {
    if (!loading && !manual) fetchData(uri, params);
  }, [manual]);

  return { data: response, loading, error, fetchData };
};
