import React, { useState } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";

import { useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useMutation } from "lib/hooks";
import MyModal from "components/Modal";

import MDButton from "components/MDButton";
import { Alert } from "@mui/material";
import { LineItem } from "./LineItem";
import RenderMerchAttrs from "./RenderMerchAttrs";
import { ValidationStyle } from "./index.style";

let prev = null;

function Validate() {
  const [merch, setMerch] = useState(null);
  const [bar, setBar] = useState(null);
  const [success, setSuccess] = useState(false);
  const { request, loading } = useMutation({ uri: "/validator" });
  const { id: eventId, type } = useParams();

  // DONT REMOVE THIS CODE
  // React.useEffect(() => {
  //   navigator.mediaDevices
  //     .enumerateDevices()
  //     .then((devices) => {
  //       devices.forEach((device) => {
  //         console.log('>>>>>>> device >>>>>', device);
  //         console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
  //       });
  //     })
  //     .catch((err) => {
  //       console.error(`${err.name}: ${err.message}`);
  //     });
  // }, []);

  const open = !!merch || success || !!bar;

  const onClose = () => {
    if (!!bar || !!merch) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("Are sure close ") === true) {
        setMerch(null);
        setSuccess(false);
        setBar(null);
      }
    } else {
      setSuccess(false);
    }
    prev = null;
  };

  const makeRequest = (qrText, uri) =>
    request({ qrText }, uri).catch((err) => {
      NotificationManager.warning(err?.message || "");
    });

  return (
    <ValidationStyle className="body">
      <div className="container">
        <div className="content">
          <MyModal
            centered
            open={open}
            onCancel={onClose}
            title={
              <h2 style={{ color: "#fff" }}>
                {bar && "Захиалга"}
                {merch && "Мерч"}
                {success && "Тикет"}
              </h2>
            }
          >
            {merch && (
              <LineItem
                qty={1}
                name={merch?.name || ""}
                image={merch ? merch?.templateId?.imageUrl[0] : ""}
                type={<RenderMerchAttrs attrs={merch?.merchItemId?.attrs || {}} />}
              />
            )}
            {success && (
              <Alert severity="success" sx={{ marginBottom: 4 }}>
                Амжилттай.
              </Alert>
            )}
            {bar && (
              <div className="barContent">
                {bar?.items?.map((barItem, idx) => (
                  <div key={idx} className="item">
                    {barItem.name} <span className="qty">{barItem.qty}</span>
                  </div>
                ))}
              </div>
            )}
            <MDButton
              size="large"
              color="white"
              variant="contained"
              disabled={loading}
              sx={{ width: "100%" }}
              onClick={onClose}
            >
              Хаах
            </MDButton>
          </MyModal>
          <QrScanner
            // deviceId="72b5e1f9add05ce085cc6c11f4f6f8197faf5dcf429220720de892571d7d75e8"
            stopDecoding={loading || open}
            onDecode={(result) => {
              if (prev !== result) {
                prev = result;

                if (result) {
                  // ticket ->> [userid,eventId, ticketId, seatId]
                  // merchant ->> [merchant, merchantId, templateId, templateItemId]
                  // bar ->> [bar, eventId, barItemId]
                  const [first, second] = result.split("__") || ["", ""];

                  if (["ticket", "merchant", "bar"].includes(first) && first && second) {
                    switch (type) {
                      case "merchant": {
                        if (first === "merchant") {
                          return makeRequest(second, `/validator/${eventId}/merch`).then((res) => {
                            if (res?._id) {
                              return setMerch(res);
                            }
                            return NotificationManager.warning(res.message || "Мерч авсан байна!");
                          });
                        }
                        return NotificationManager.warning("Мерчын QR биш байна");
                      }
                      case "bar":
                        if (first === "bar") {
                          return makeRequest(second, `/validator/${eventId}/bar`).then((res) => {
                            if (res?._id) {
                              return setBar(res);
                            }
                            return NotificationManager.warning(
                              res.message || "Захиалга авсан байна!"
                            );
                          });
                        }
                        return NotificationManager.warning("Bar - ны QR биш байна");
                      default:
                        if (first !== "merchant" && first !== "bar") {
                          return makeRequest(second, `/validator/${eventId}/ticket`).then((res) => {
                            if (res?.code === "ticketVerified") {
                              setSuccess(true);
                            } else {
                              return NotificationManager.warning(
                                res.message || "Тасалбарыг ашигласан байна!"
                              );
                            }
                          });
                        }
                        return NotificationManager.warning("Тикетын QR биш байна");
                    }
                  }
                }
                NotificationManager.warning("INVALID QR");
              }
            }}
            onError={(error) => {
              console.error(error);
              NotificationManager.warning("QR унших явцад алдаа гарлаа");
            }}
          />
          <div style={{ height: 40 }} />
          <MDButton
            size="large"
            color="white"
            variant="contained"
            disabled={loading}
            sx={{ width: "100%" }}
            onClick={() => {
              prev = null;
            }}
          >
            Refresh
          </MDButton>
        </div>
      </div>
    </ValidationStyle>
  );
}

export default Validate;
