import React from "react";

import styled from "styled-components";

function RenderMerchAttrs({ attrs }) {
  return Object.entries(attrs).map(([attrkey, attr], idx) => (
    <RenderStyle key={attr}>
      {idx > 0 ? " ∙ " : ""}
      {attrkey === "color" ? (
        <RenderColor color={attr} />
      ) : (
        <span className="attr">
          {attrkey} : <strong>{attr}</strong>
        </span>
      )}
    </RenderStyle>
  ));
}

export default RenderMerchAttrs;

export function RenderColor({ color }) {
  return (
    <span
      style={{
        width: 20,
        height: 20,
        background: color,
        display: "inline-block",
        borderRadius: "50px",
        margin: "2px 4px 0 0",
        border: "2px solid #242424",
      }}
    />
  );
}

const RenderStyle = styled.span`
  .attr {
    color: #929292;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 24px; /* 171.429% */
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    strong {
      color: #fff;
      font-size: 16px;
    }
  }
`;
