import React, { useEffect, useState } from "react";
import MyModal from "components/Modal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { NotificationManager } from "react-notifications";
import { useMutation } from "lib/hooks";
import paymentMethods from "../../../paymentMethods";
import CheckBox from "../../../components/CheckBox";
import MDAvatar from "../../../components/MDAvatar";

function PaymentModal({ id, acceptablePaymentMethods, refetch }) {
  const [selected, setSelected] = useState(acceptablePaymentMethods);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setSelected(acceptablePaymentMethods);
    }
  }, [open]);

  const { request, loading } = useMutation({
    uri: `/event/payment-method/${id}`,
    method: "put",
  });

  if (!id) return null;

  return (
    <>
      <MDBox mb={3} gap={2} display="flex">
        {acceptablePaymentMethods.map((accept) => {
          const method = paymentMethods.find((p) => p.code === accept);
          if (!method) return null;
          return <MDAvatar src={method.logo} key={accept} variant="rounded" />;
        })}
      </MDBox>
      <MDButton
        size="large"
        type="button"
        variant="gradient"
        color="secondary"
        onClick={() => setOpen(true)}
      >
        update
      </MDButton>
      <MyModal open={open} onClose={() => setOpen(false)} title="Payment methods">
        <MDBox mb={3}>
          {paymentMethods.map((method) => (
            <CheckBox
              label={method.name}
              value={selected.includes(method.code)}
              onChange={() => {
                if (!selected.includes(method.code)) {
                  setSelected((prev) => [...prev, method.code]);
                } else {
                  setSelected((prev) => prev.filter((p) => p !== method.code));
                }
              }}
            />
          ))}
        </MDBox>
        <MDBox display="flex" justifyContent="flex-end">
          <MDButton
            loading={loading}
            onClick={() => {
              if (!selected?.length)
                return NotificationManager.warning("Please select payment method");
              const dto = selected.filter((p) => p !== "monpayqr");
              if (dto.includes("monpay")) {
                dto.push("monpayqr");
              }

              request({ acceptablePaymentMethods: dto })
                .then((res) => {
                  NotificationManager.success(res?.message || "Saved");
                  setOpen(false);
                  refetch();
                })
                .catch((e) => NotificationManager.warning(e?.message || e));
            }}
            ml="auto"
            color="info"
          >
            Save
          </MDButton>
        </MDBox>
      </MyModal>
    </>
  );
}

export default PaymentModal;
