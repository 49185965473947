import React, { useRef } from "react";
import { InputLabel } from "@mui/material";
import { NotificationManager } from "react-notifications";
import placeholder from "assets/images/placeholder.png";
import styled from "styled-components";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ImageReSizer from "react-image-file-resizer";
import Icon from "@mui/material/Icon";
import LoadSvg from "./LoadSvg";
import { CDN_BASE } from "../constant";

async function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

async function loadImageAsync(src) {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => {
      resolve(image);
    };

    image.onerror = (error) => {
      reject(error);
    };

    image.src = src;
  });
}

function ImageUpload({ value, label, onChange, request, loading, onRemove, isSvg }) {
  const inputRef = useRef();

  const uploadImage = (image) => {
    const formData = new FormData();
    formData.append("image", image);
    return request(formData, "/s3-image-upload", {
      "content-type": "multipart/form-data",
    })
      .then((res) => {
        if (res?.imageUrl) {
          onChange(
            res.imageUrl
              ?.replace("https://ticket-x-bucket.s3.us-west-2.amazonaws.com/", CDN_BASE)
              ?.replace("https://ticket-x-bucket.s3.amazonaws.com/", CDN_BASE)
          );
          return NotificationManager.success("Successfully uploaded");
        }
        NotificationManager.warning("Successfully uploaded");
      })
      .catch((e) => {
        console.error(e);
        NotificationManager.warning(e?.message || "Oops something went wrong");
      });
  };

  return (
    <>
      <InputLabel sx={{ marginBottom: "12px" }}>{label}</InputLabel>
      <ImageUploadStyle
        type="button"
        className={loading ? "loading" : ""}
        onClick={() => inputRef.current?.click()}
      >
        {loading && (
          <div className="load">
            <LoadSvg />
          </div>
        )}
        {onRemove && (
          <button type="button" className="remove-btn" onClick={onRemove}>
            <Icon>delete</Icon>
          </button>
        )}
        <div className="hover">
          <CloudUploadOutlinedIcon color="white" size={24} />
        </div>
        <img src={value || placeholder} alt="ticketx" style={{ width: "100%" }} />
        <input
          type="file"
          ref={inputRef}
          multiple={false}
          accept="image/*"
          style={{ display: "none" }}
          onChange={async (event) => {
            const originalImage = event.target.files[0];
            let maxW = 1280;
            let maxH = 1280;
            if (isSvg)
              return uploadImage(originalImage).then(() => {
                event.target.value = "";
              });
            if (originalImage) {
              const file = event.target.files[0];
              if (file) {
                const dataURL = await readFileAsync(file);
                const image = await loadImageAsync(dataURL);

                maxW = image.width * 0.7;
                maxH = image.height * 0.7;
              }
            }

            ImageReSizer.imageFileResizer(
              originalImage,
              maxW,
              maxH,
              "jpeg",
              90,
              0,
              (blob) =>
                uploadImage(blob).then(() => {
                  event.target.value = "";
                }),
              "file",
              400,
              400
            );
          }}
        />
      </ImageUploadStyle>
    </>
  );
}

export default ImageUpload;

const ImageUploadStyle = styled.button`
  cursor: pointer;
  position: relative;
  background-color: transparent;
  .remove-btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 20px;
    position: absolute;
    right: -16px;
    top: -16px;
    z-index: 10;
    cursor: pointer;
  }
  .load {
    position: absolute;
    inset: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
  }
  input {
    display: none;
  }

  img {
    height: 100%;
    object-fit: cover;
    max-height: 300px;
  }

  *,
  *::before,
  *::after {
    outline: none !important;
    border: none !important;
  }

  .hover {
    opacity: 0;
    position: absolute;
    inset: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.6);
    svg {
      width: 48px;
      height: 48px;
      transform: translateY(40px);
      transition: all 0.3s ease-in-out;
    }
  }
  &:hover {
    .hover {
      opacity: 1;
      svg {
        transform: translateY(0);
      }
    }
  }
`;
