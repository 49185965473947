import React, { useEffect } from "react";
import MyModal from "components/Modal";
import FormItem from "components/FormItem";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Form from "rc-field-form";
import { NotificationManager } from "react-notifications";
import { useMutation } from "lib/hooks";
import Grid from "@mui/material/Grid";
import MoneyInput from "components/MoneyInput";
import ImageUpload from "../../../components/ImageUpload";

function AddOrEditModal({ open, onClose, item, id, refetch }) {
  const [form] = Form.useForm();

  const { request, loading } = useMutation({
    uri: `/ticket-bar/${id}/bar-item${item?.id ? `/${item.id}` : ""}`,
    method: item?.id ? "put" : "post",
  });

  const { request: upload, loading: uploading } = useMutation({ uri: "/s3-image-upload" });

  useEffect(() => {
    form.setFieldsValue(item);
  }, [item]);

  return (
    <MyModal open={open} onClose={onClose} title="Bar Item">
      <Form
        form={form}
        initialValues={item}
        onFinish={(values) => {
          request({ ...item, ...values, ...(item.id ? {} : { qty: values.avail }) })
            .then((res) => {
              if (res?._id) {
                onClose();
                refetch();
                form.resetFields();
                NotificationManager.success("Saved");
              }
            })
            .catch((e) => {
              NotificationManager.warning(e.message || "ERROR");
            });
        }}
      >
        <Grid container spacing={2} mb={3}>
          <Grid item md={6}>
            <FormItem name="name" rules={[{ required: true }]}>
              <MDInput
                fullWidth
                label="Name"
                variant="standard"
                placeholder="Heineken"
                InputLabelProps={{ shrink: true }}
              />
            </FormItem>
          </Grid>
          <Grid item md={6}>
            <FormItem name="name_en" rules={[{ required: true }]}>
              <MDInput
                fullWidth
                label="Name En"
                variant="standard"
                placeholder="Heineken"
                InputLabelProps={{ shrink: true }}
              />
            </FormItem>
          </Grid>
          <Grid item md={6}>
            <FormItem name="type" rules={[{ required: true }]}>
              <MDInput
                fullWidth
                label="Type"
                variant="standard"
                placeholder="Beer"
                InputLabelProps={{ shrink: true }}
              />
            </FormItem>
          </Grid>
          <Grid item md={6}>
            <MoneyInput />
          </Grid>
          <Grid item md={6}>
            <FormItem name="image" rules={[{ required: true }]}>
              <ImageUpload label="Image" request={upload} loading={uploading} />
            </FormItem>
          </Grid>
          <Grid item md={6}>
            <FormItem name="avail" rules={[{ required: true }]}>
              <MDInput
                fullWidth
                label="Available"
                variant="standard"
                placeholder="100"
                InputLabelProps={{ shrink: true }}
              />
            </FormItem>
          </Grid>
        </Grid>
        <MDBox display="flex" justifyContent="flex-end">
          <MDButton type="submit" loading={loading} disabled={uploading} ml="auto" color="info">
            Save
          </MDButton>
        </MDBox>
      </Form>
    </MyModal>
  );
}

export default AddOrEditModal;
