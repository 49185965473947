import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MyModal from "components/Modal";
import NumberInput from "components/NumberInput";
import { useMutation, useQuery } from "lib/hooks";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";

function HoldModal({ hallPlan, isSeat, eventId }) {
  const [open, setOpen] = useState(false);
  const [qty, setQty] = useState(0);
  const { id } = useParams();
  const { data, fetchData, loading } = useQuery({
    uri: `/ticket-holder/${id}`,
  });
  const { request, loading: holding } = useMutation({
    uri: `/ticket-holder/hold`,
  });
  const { request: unhold, loading: unholding } = useMutation({
    uri: `/ticket-holder/hold`,
    method: "patch",
  });

  useEffect(() => {
    if (open && !loading) {
      fetchData();
    }
  }, [open]);

  return (
    <>
      <MDButton
        variant="gradient"
        color="light"
        size="large"
        onClick={() => {
          setOpen(true);
          setQty(0);
        }}
      >
        Hold
      </MDButton>

      <MyModal open={open} onClose={() => setOpen(false)} title="Hold Ticket" sx={{ width: 750 }}>
        {data?.map((item) => (
          <Card sx={{ marginBottom: 2 }} key={item._id}>
            <MDBox display="flex" justifyContent="space-between" p={1}>
              <MDTypography>
                On hold tickets: <strong>{item.on_hold_cnt || item.on_hold?.length}</strong>
              </MDTypography>

              <MDButton
                type="submit"
                variant="gradient"
                color="warning"
                loading={unholding}
                onClick={() => {
                  unhold({}, `/ticket-holder/unHold/${item._id}`)
                    .then((res) => {
                      NotificationManager.success(res?.message || "Success");
                      fetchData();
                    })
                    .catch((er) => {
                      NotificationManager.success(er?.message || er);
                    });
                }}
              >
                Unhold
              </MDButton>
            </MDBox>
          </Card>
        ))}
        {isSeat ? null : (
          <>
            <MDBox mb={4}>
              <NumberInput label="Hold number" placeholder="200" value={qty} onChange={setQty} />
            </MDBox>
            <MDButton
              type="submit"
              variant="gradient"
              color="info"
              loading={holding}
              onClick={() => {
                request({
                  templateId: id,
                  seats: qty,
                })
                  .then((res) => {
                    NotificationManager.success(res?.message || "Success");
                    setOpen(false);
                  })
                  .catch((er) => {
                    NotificationManager.success(er?.message || er);
                  });
              }}
            >
              Hold
            </MDButton>
          </>
        )}
      </MyModal>
    </>
  );
}

export default HoldModal;
