import React from "react";
import MyModal from "components/Modal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import { useMutation } from "lib/hooks";
import { NotificationManager } from "react-notifications";

function DeleteModal({ open, onClose, active, isSponsor, onSuccess }) {
  const { request, loading } = useMutation({
    uri: `/event/${isSponsor ? "sponsor" : "collaborator"}/${active?._id}`,
    method: "delete",
  });

  return (
    <MyModal open={open} onClose={onClose} title="Are you sure delete ?">
      <MDBox component="li" display="flex" alignItems="center" py={1} mb={1}>
        <MDBox mr={2}>
          <MDAvatar src={active?.userId?.profile_image} alt="something here" shadow="md" />
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <MDTypography variant="button" fontWeight="medium">
            {active?.userId?.display_name}
          </MDTypography>
          <MDTypography variant="caption" color="text">
            {active?.role}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex" justifyContent="flex-end" gap={2}>
        <MDButton color="secondary" onClick={onClose}>
          Cancel
        </MDButton>
        <MDButton
          color="info"
          loading={loading}
          onClick={() => {
            request()
              .then(onSuccess)
              .catch((err) => {
                NotificationManager.warning(err.message || err);
              });
          }}
        >
          Delete
        </MDButton>
      </MDBox>
    </MyModal>
  );
}

export default DeleteModal;
