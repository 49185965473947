import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import dayjs from "dayjs";
import EditCell from "examples/Tables/EditCell";
import { useQuery } from "lib/hooks";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import MDAvatar from "components/MDAvatar";
import DefaultCell from "examples/Tables/DefaultCell";

function Lounges() {
  const { data, loading } = useQuery({ uri: "/ticket-bar" });

  return (
    <>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDButton variant="gradient" color="info" component={Link} to="/lounges/new">
          new lounge
        </MDButton>
      </MDBox>
      <Card>
        <DataTable
          loading={loading}
          entriesPerPage={false}
          table={{
            columns: [
              {
                Header: "Event image",
                accessor: "eventId.cover_image_v",
                Cell: ({ value, row }) => (
                  <MDAvatar
                    size="xl"
                    src={value}
                    variant="rounded"
                    alt={row?.original?.name || "ticketx"}
                  />
                ),
              },
              {
                Header: "Event name",
                accessor: "eventId.name",
                Cell: ({ value }) => <DefaultCell value={value} />,
              },
              {
                Header: "Item images",
                accessor: "items",
                Cell: ({ value }) => (
                  <MDBox display="flex" gap={1} flexWrap="wrap">
                    {value.map((item) => (
                      <MDAvatar size="sm" src={item.image} variant="rounded" alt="ticketx" />
                    ))}
                  </MDBox>
                ),
              },
              {
                Header: "Open date",
                accessor: "openDate",
                Cell: ({ value }) => (
                  <DefaultCell value={dayjs(value).format("YYYY-MM-DD HH:mm")} />
                ),
              },
              {
                Header: "Close date",
                accessor: "closeDate",
                Cell: ({ value }) => (
                  <DefaultCell value={dayjs(value).format("YYYY-MM-DD HH:mm")} />
                ),
              },
              {
                sorted: "none",
                accessor: "_id",
                Header: "Action",
                Cell: ({ value }) => <EditCell value={`/lounges/${value}`} />,
              },
            ],
            rows: data || [],
          }}
        />
      </Card>
    </>
  );
}

export default Lounges;
