import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";

function SingleEvent({ onClick, event }) {
  return (
    <SingleEventStyle type="button" onClick={onClick}>
      <img src={event.cover_image || event.cover_image_v} alt="dashboard" />
      <div className="wrap">
        <div className="top">
          <div />
          <div className="date">
            <div className="day">{dayjs(event.startDate).format("DD")}</div>
            <div className="month">{dayjs(event.startDate).format("MMM")}</div>
          </div>
        </div>
        <div>
          <div className="location">{event.location.name}</div>
          <div className="name">{event.name}</div>
        </div>
      </div>
    </SingleEventStyle>
  );
}

export default SingleEvent;

const SingleEventStyle = styled.button`
  width: 100%;
  display: block;
  position: relative;
  border-radius: 16px;
  padding: 0;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 9 / 4;
    border-radius: 16px;
    min-height: 140px;
  }
  .wrap {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    inset: 0;
    border-radius: 16px;
    background: linear-gradient(0deg, #090909 0%, rgba(9, 9, 9, 0) 100%);
  }

  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .date {
    gap: 4px;
    width: 68px;
    height: 68px;
    display: flex;
    padding: 8px 0 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 12px;
    background: #e6e6e6;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    .day {
      margin: 0;
      color: #000;
      text-align: center;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      font-family: var(--font-okta);
    }
    .month {
      color: #000;
      text-align: center;
      font-family: var(--font-okta);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 1;
    }
  }

  .location {
    color: #e1e1e1;
    margin-bottom: 16px;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    font-family: var(--font-okta);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    line-height: 10px; /* 171.429% */
  }

  .name {
    margin-bottom: 18px;
    color: #fff;
    font-family: var(--font-okta);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 120% */
    text-align: left;
  }

  @media all and (max-width: 991px) {
    .cover {
      img {
        aspect-ratio: 5 / 3 !important;
      }
    }

    .date {
      width: 60px;
      height: 60px;
      gap: 2px;
      padding: 4px 0 0;
      .day {
        font-size: 32px;
      }
      .month {
        color: #000;
        text-align: center;
        font-family: var(--font-okta);
        font-size: 13px;
      }
    }

    .name {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }
`;
