import React from "react";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import paymentMethods from "../../../paymentMethods";
import CheckBox from "../../../components/CheckBox";

export default function AcceptablePayment({ value = [], onChange }) {
  return (
    <MDBox>
      {paymentMethods.map((method) => (
        <CheckBox
          value={value.includes(method.code)}
          onChange={(checked) => {
            const dto = value.filter((p) => p !== "monpayqr");

            if (checked) {
              if (method.code === "monpay") {
                dto.push("monpayqr");
              }
              onChange([...(dto || []), method.code]);
            } else {
              onChange(dto.filter((v) => v !== method.code));
            }
          }}
          label={<MDTypography>{method.name}</MDTypography>}
        />
      ))}
    </MDBox>
  );
}
