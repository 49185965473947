import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "lib/hooks";

import Card from "@mui/material/Card";
import MDAvatar from "components/MDAvatar";
import DataTable from "examples/Tables/DataTable";

// Data
import dayjs from "dayjs";

import Loader from "components/Loader";
import DefaultCell from "examples/Tables/DefaultCell";
import MDBox from "components/MDBox";

export default function NFTCollection() {
  const { id } = useParams();
  const { data, loading } = useQuery({ uri: `/mongolnft/${id}/nft-templates/?limit=100` });

  return (
    <Card>
      {loading ? (
        <Loader />
      ) : (
        <DataTable
          entriesPerPage={false}
          table={{
            columns: [
              {
                Header: "Image",
                accessor: "image",
                Cell: ({ value }) => <MDAvatar src={value} size="xl" variant="rounded" />,
              },
              {
                Header: "Name",
                accessor: "name",
                Cell: ({ value }) => <DefaultCell value={value} />,
              },
              {
                Header: "Description",
                accessor: "description",
                Cell: ({ value }) => <DefaultCell value={value} />,
              },
              {
                Header: "Total nfts",
                accessor: "totalNFTs",
                Cell: ({ value }) => <DefaultCell value={`${value}`} />,
              },
              {
                Header: "Is locked",
                accessor: "isLocked",
                Cell: ({ value }) => <DefaultCell value={`${value}`} />,
              },
              {
                Header: "Creator",
                accessor: "creator",
                Cell: ({ value }) => (
                  <MDBox display="flex" alignItems="center" gap={1}>
                    <MDAvatar src={value?.profilePicture} size="sm" />
                    <DefaultCell value={value?.userName} />
                  </MDBox>
                ),
              },
              {
                Header: "Created at",
                accessor: "createdAt",
                Cell: ({ value }) => (
                  <DefaultCell value={dayjs(value).format("YYYY-MM-DD HH:mm")} />
                ),
              },
            ],
            rows: data?.list || [],
          }}
        />
      )}
    </Card>
  );
}
