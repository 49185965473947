import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import AuthLayout from "../layouts/AuthLayout";

import Login from "./auth";
import Forgot from "./auth/forgot";
import MFA from "./auth/mfa";
import Events from "./events";
import TicketTempaltes from "./ticket-templates";
import TicketTempalteDetail from "./ticket-templates/[id]";
import EventDetail from "./events/[id]";
import Users from "./users";
import UserDetail from "./users/[id]";
import MerchTemplates from "./merchant/merch-templates";
import MerchTemplateDetail from "./merchant/merch-templates/[id]";
import Lounges from "./lounges";
import LoungeDetail from "./lounges/[id]";
import Tickets from "./tickets";
import Merchants from "./merches";
import Validate from "./validate";
import Validators from "./validators";
import CreateValidator from "./validators/new";
import ValidateRedirect from "./validate/[id]";
import ValidateType from "./validate/[id]/[type]";
import NFTCollections from "./nft-collections";
import NFTTemplates from "./nft-collections/[id]";
import Refund from "./refund";

import Media from "./media";

function Pages() {
  return (
    <Routes>
      <Route
        path="/auth"
        element={
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        }
      >
        <Route index element={<Login />} />
        <Route path="forgot" element={<Forgot />} />
        <Route path="mfa" element={<MFA />} />
      </Route>
      <Route
        path="*"
        element={
          <DashboardLayout>
            <Outlet />
          </DashboardLayout>
        }
      >
        <Route index element={<Events />} />
        <Route path="events" element={<Events />} />
        <Route path="users" element={<Users />} />
        <Route path="media" element={<Media />} />
        <Route path="tickets" element={<Tickets />} />
        <Route path="merches" element={<Merchants />} />
        <Route path="users/:username" element={<UserDetail />} />
        <Route path="events/:id" element={<EventDetail />} />
        <Route path="ticket-templates" element={<TicketTempaltes />} />
        <Route path="ticket-templates/:id" element={<TicketTempalteDetail />} />
        <Route path="merchant/templates" element={<MerchTemplates />} />
        <Route path="merchant/templates/:id" element={<MerchTemplateDetail />} />
        <Route path="lounges" element={<Lounges />} />
        <Route path="nft-collections" element={<NFTCollections />} />
        <Route path="nft-collections/:id" element={<NFTTemplates />} />
        <Route path="lounges/:id" element={<LoungeDetail />} />
        <Route path="validate" element={<Validate />} />
        <Route path="validators" element={<Validators />} />
        <Route path="validators/new" element={<CreateValidator />} />
        <Route path="validate/:id" element={<ValidateRedirect />} />
        <Route path="validate/:id/:type" element={<ValidateType />} />
        <Route path="refund" element={<Refund />} />
      </Route>
    </Routes>
  );
}

export default Pages;
