import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import EditCell from "examples/Tables/EditCell";
import { useQuery } from "lib/hooks";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import MDAvatar from "components/MDAvatar";
import DefaultCell from "examples/Tables/DefaultCell";

function TicketTempaltes() {
  const { data, loading } = useQuery({ uri: "/user/headless" });

  return (
    <>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDButton variant="gradient" color="info" component={Link} to="/users/new">
          new user
        </MDButton>
      </MDBox>
      <Card>
        <DataTable
          loading={loading}
          entriesPerPage={false}
          table={{
            columns: [
              {
                Header: "Profile",
                accessor: "profile_image",
                Cell: ({ value }) => (
                  <MDAvatar size="xl" src={value} variant="rounded" alt="ticketx" />
                ),
              },
              {
                Header: "Display name",
                accessor: "display_name",
                Cell: ({ value }) => <DefaultCell value={value} />,
              },
              {
                Header: "Username",
                accessor: "username",
                Cell: ({ value }) => <DefaultCell value={value} />,
              },
              {
                Header: "email",
                accessor: "email",
                Cell: ({ value }) => <DefaultCell value={value} />,
              },
              {
                sorted: "none",
                accessor: "_id",
                Header: "Action",
                Cell: ({ row }) => <EditCell value={`/users/${row.original.username}`} />,
              },
            ],
            rows: data || [],
          }}
        />
      </Card>
    </>
  );
}

export default TicketTempaltes;
