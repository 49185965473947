import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import barQr from "assets/images/bar-qr.png";
import ticketQr from "assets/images/ticket-qr.png";
import merchQr from "assets/images/merch-qr.png";
import { useQuery } from "../../lib/hooks";
import Loader from "../../components/Loader";
import SingleEvent from "../../components/SingleEvent";
import MyModal from "../../components/Modal";

function Validate() {
  const [active, setActive] = useState(null);
  const { data, loading } = useQuery({ uri: "/validator/get/validator/event" });

  const events = (data || [])?.reduce((acc, cur) => {
    acc.push(...(cur?.events || []));
    return acc;
  }, []);

  return (
    <ValidateStyle>
      <MyModal open={!!active} title="Choose your position" onClose={() => setActive(null)}>
        <ModalContent>
          <Link to={`/validate/${active}/ticket`} className="link">
            <img src={ticketQr} alt="dash" />
            <p>Ticket</p>
          </Link>
          <Link to={`/validate/${active}/merchant`} className="link">
            <img src={merchQr} alt="dash" />
            <p>Merchant</p>
          </Link>
          <Link to={`/validate/${active}/bar`} className="link">
            <img src={barQr} alt="dash" />
            <p>Bar</p>
          </Link>
        </ModalContent>
      </MyModal>
      {loading ? (
        <Loader />
      ) : (
        <div className="events">
          {events?.map((event) => (
            <SingleEvent event={event} onClick={() => setActive(event._id)} key={event._id} />
          ))}
        </div>
      )}
    </ValidateStyle>
  );
}

export default Validate;

const ValidateStyle = styled.div`
  .events {
    gap: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

const ModalContent = styled.div`
  a.link {
    gap: 16px;
    display: flex;
    padding: 8px;
    font-weight: 500;
    color: #242424 !important;
    border-radius: 8px;
    align-items: center;
    background-color: #f9f9f9;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    img {
      width: 28px;
      height: 28px;
    }
  }
`;
