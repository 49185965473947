import React, { useEffect } from "react";
import MyModal from "components/Modal";
import Form from "rc-field-form";
import Grid from "@mui/material/Grid";
import FormItem from "components/FormItem";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { NotificationManager } from "react-notifications";
import { useMutation } from "lib/hooks";
import MoneyInput from "components/MoneyInput";
import { InputLabel } from "@mui/material";
import styled from "styled-components";
import { getKeyFromName } from "lib/utils/merchandise";
import NumberInput from "components/NumberInput";
import RenderOption from "./RenderOption";

function ItemModal({ open, onClose, item, refetch, attrs, merchTemplateId }) {
  const [form] = Form.useForm();
  const isNew = !item?._id;
  const { request, loading: saving } = useMutation({
    uri: `/merch-template/item${item?._id ? `/${item?._id}` : ""}`,
    method: isNew ? "post" : "put",
  });

  useEffect(() => {
    if (item) {
      form.setFieldsValue(item);
    }
  }, [item]);

  return (
    <MyModal open={open} onClose={onClose} title="Item">
      <Form
        form={form}
        initialValues={item}
        onFinish={(values) => {
          request({ merchTemplateId, avail: values.qty, ...values })
            .then(() => {
              NotificationManager.success("Saved");
              refetch();
              onClose();
            })
            .catch((e) => {
              console.error(e);
            });
        }}
      >
        <Grid container spacing={2} mb={2}>
          <Grid item md={6}>
            <MoneyInput />
          </Grid>
          <Grid item md={6}>
            <FormItem name="qty" rules={[{ required: true }]}>
              <NumberInput label="Qty" placeholder="1200" />
            </FormItem>
          </Grid>
          {attrs?.map((attr) => (
            <Grid item md={6} key={attr.name_en}>
              <FormItem name={["attrs", getKeyFromName(attr.name_en)]}>
                <Options
                  label={attr.name_en}
                  options={attr.options}
                  isColor={getKeyFromName(attr.name_en) === "color"}
                />
              </FormItem>
            </Grid>
          ))}
        </Grid>
        <MDBox display="flex" justifyContent="flex-end">
          <MDButton variant="contained" color="info" type="submit" loading={saving}>
            save
          </MDButton>
        </MDBox>
      </Form>
    </MyModal>
  );
}

export default ItemModal;

function Options({ options, value, onChange, label, isColor }) {
  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <MDBox mt={1} display="flex" gap={2} flexWrap="wrap" alignItems="center">
        {options?.map((option) => (
          <StyledButton
            key={option.value}
            onClick={() => onChange(option.value)}
            className={`${value === option.value ? "active" : ""} ${isColor ? "color" : ""}`}
          >
            <RenderOption isColor={isColor} value={option.value} />
          </StyledButton>
        ))}
      </MDBox>
    </div>
  );
}

const StyledButton = styled.div`
  height: 24px;
  cursor: pointer;
  position: relative;
  &::after {
    content: "✓";
    position: absolute;
    color: #fff;
    font-size: 16px;
    display: flex;
    top: -8px;
    right: -4px;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    transition: all 0.2s ease-in-out;
  }
  &.color {
    &::after {
      inset: 0;
    }
  }
  &.active {
    &::after {
      transform: scale(1);
    }
  }
`;
