import React, { useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import { useQuery } from "lib/hooks";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import dayjs from "dayjs";
import EventAutocomplete from "../ticket-templates/EventAutocomplete";
import RenderOption from "../merchant/merch-templates/[id]/RenderOption";
import CheckBox from "../../components/CheckBox";
import { initialEvent } from "../../constant";

function Tickets() {
  const [event, setEvent] = useState(initialEvent);
  const [q, setQ] = useState("");
  const [onlyNotUsed, setOnlyNotUsed] = useState(false);

  const { loading, data, fetchData } = useQuery({
    uri: `/ticket/${initialEvent._id}`,
    manual: true,
  });

  useEffect(() => {
    if (event?._id)
      fetchData(`/merch/query`, {
        eventId: event?._id,
        isUsed: onlyNotUsed === true ? false : undefined,
      });
  }, [event, onlyNotUsed]);

  const soldCounts = useMemo(
    () =>
      data?.merch?.reduce(
        (acc, cur) => {
          if (!acc[cur.name]) {
            acc[cur.name] = 1;
          } else {
            acc[cur.name] += 1;
          }
          acc["Total sold"] += 1;
          return acc;
        },
        { "Total sold": 0 }
      ),
    [loading]
  );
  const regex = new RegExp(q, "i"); // 'i' flag makes the search case-insensitive

  const tickets = useMemo(() => {
    if (data)
      return data?.merch
        ?.filter((d) => regex.test(d.ownerId.username) || regex.test(d.ownerId.email))
        .sort((a, b) => {
          if (a.username < b.username) {
            return -1;
          }
          if (a.username > b.username) {
            return 1;
          }
          return 0;
        });
    return [];
  }, [q, loading]);

  return (
    <div>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Card p={3}>
            <MDBox p={4}>
              <MDBox display="flex" alignItems="center" justifyContent="space-between" mb={5}>
                <MDBox display="flex" alignItems="center" gap={4} flex="1" flexWrap="wrap">
                  <div style={{ width: "100%", flex: "0 0 250px" }}>
                    <EventAutocomplete value={event} onChange={setEvent} />
                  </div>
                  <MDInput
                    value={q}
                    fullWidth
                    sx={{ width: "230px" }}
                    onChange={(e) => setQ(e.target.value)}
                    placeholder="Search by username or email"
                  />
                  <CheckBox
                    value={onlyNotUsed}
                    onChange={() => setOnlyNotUsed(!onlyNotUsed)}
                    label="Show only not used?"
                  />
                </MDBox>
              </MDBox>
              <div
                style={{
                  display: "grid",
                  gap: 16,
                  gridTemplateColumns: "repeat(auto-fill, minmax(250px,1fr))",
                }}
              >
                {tickets.map((ticket) => (
                  <Card key={ticket.email}>
                    <MDBox p={2} sx={{ overflow: "hidden" }}>
                      <MDTypography whiteSpace="nowrap">{ticket.name}</MDTypography>
                      <div style={{ lineHeight: 1 }}>
                        <MDTypography whiteSpace="nowrap" variant="caption">
                          {ticket.ownerId.username}
                        </MDTypography>
                      </div>
                      <div style={{ lineHeight: 1 }}>
                        <MDTypography whiteSpace="nowrap" variant="caption">
                          {ticket.ownerId.email}
                        </MDTypography>
                      </div>
                      <div style={{ lineHeight: 1 }}>
                        <MDTypography whiteSpace="nowrap" variant="caption">
                          Created at:{" "}
                          {dayjs(new Date(ticket.createdAt)).format("YYYY/MM/DD HH:mm:ss")}
                        </MDTypography>
                      </div>
                      <MDBox display="flex" flexWrap="wrap" gap={2} alignItems="center" mt={2}>
                        {Object.entries(ticket?.merchItemId?.attrs || {}).map(([label, value]) => (
                          <RenderOption
                            isColor={label === "color"}
                            value={value}
                            label={label}
                            key={label}
                          />
                        ))}
                      </MDBox>
                    </MDBox>
                  </Card>
                ))}
              </div>
              <MDBox
                mt={4}
                display="flex"
                gap="4px 12px"
                alignItems="center"
                flexWrap="wrap"
                justifyContent="flex-end"
              >
                {Object.entries(soldCounts || {}).map(([name, value]) => (
                  <MDTypography key={name}>
                    {name} : <strong>{value} ш</strong>
                  </MDTypography>
                ))}
              </MDBox>
            </MDBox>
          </Card>
        </>
      )}
    </div>
  );
}

export default Tickets;
