import React, { useState } from "react";
import { useMutation } from "../../lib/hooks";
import MDButton from "../../components/MDButton";
import { CDN_BASE } from "../../constant";

function Index() {
  const [image, setImage] = useState();
  const [res, setRes] = useState("");
  const { request, loading } = useMutation({ uri: "/s3-image-upload" });

  return (
    <div>
      <input
        type="file"
        accept={"image/*"}
        onChange={(e) => {
          setImage(e.target.files);
        }}
      />
      <div>
        {res?.imageUrl?.replace("https://ticket-x-bucket.s3.us-west-2.amazonaws.com/", CDN_BASE)}
      </div>
      <MDButton
        type="button"
        loading={loading}
        onClick={() => {
          const formData = new FormData();
          formData.append("image", image[0]);

          return request(formData, "/s3-image-upload", {
            "content-type": "multipart/form-data",
          }).then(setRes);
        }}
      >
        Upload
      </MDButton>
    </div>
  );
}

export default Index;
