/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

import DataTable from "examples/Tables/DataTable";

// Data
import { Link } from "react-router-dom";
import React, { useState } from "react";
import dayjs from "dayjs";

import MDButton from "components/MDButton";
import { useQuery } from "lib/hooks";

import Loader from "components/Loader";
import DefaultCell from "examples/Tables/DefaultCell";
import EditCell from "examples/Tables/EditCell";
import MDAvatar from "components/MDAvatar";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import { Button } from "@mui/material";
import PointOfSale from "@mui/icons-material/PointOfSale";
import CloneCell from "../../examples/Tables/CloneCell";
import ChildModal from "./ChildModal";
import EbarimtMerchantModal from "./EbarimtMerchantModal";

function DataTables() {
  const [parent, setParent] = useState(null);
  const [merch, setMerch] = useState(null);
  const { data, loading, fetchData: refetch } = useQuery({ uri: "/event" });
  const {
    data: data1,
    fetchData,
    loading: loading1,
  } = useQuery({ uri: `/event/1/duplicate`, manual: true });

  // TODO: munkunu
  const handleCloneClick = async (value) => {
    fetchData(`/event/${value}/duplicate`).then(() => {
      refetch();
    });
  };
  return (
    <>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDButton variant="gradient" color="info" component={Link} to="/events/new">
          new event
        </MDButton>
        <ChildModal
          onClose={() => setParent(null)}
          open={!!parent}
          parent={parent}
          setMerch={setMerch}
        />
        <EbarimtMerchantModal onClose={() => setMerch(null)} open={!!merch} event={merch} />
      </MDBox>
      <Card>
        {loading ? (
          <Loader />
        ) : (
          <DataTable
            canSearch
            entriesPerPage={false}
            table={{
              columns: [
                {
                  Header: "Cover",
                  accessor: "cover_image_v",
                  Cell: ({ value, row }) => (
                    <MDAvatar
                      size="xl"
                      src={value}
                      variant="rounded"
                      alt={row?.original?.name || "ticketx"}
                    />
                  ),
                },
                {
                  Header: "Name",
                  accessor: "name",
                  Cell: ({ value }) => <DefaultCell value={value} />,
                },
                {
                  Header: "Description",
                  accessor: "description",
                  Cell: ({ value }) => <DefaultCell value={value} />,
                },
                {
                  Header: "Location",
                  accessor: "location",
                  Cell: ({ value }) => <DefaultCell value={value?.name} />,
                },
                {
                  Header: "Start date",
                  accessor: "startDate",
                  Cell: ({ value }) => (
                    <DefaultCell value={dayjs(value).format("YYYY-MM-DD HH:mm")} />
                  ),
                },
                {
                  sorted: "none",
                  accessor: "_id",
                  Header: "Actions",
                  Cell: ({ value, row }) => (
                    <div style={{ display: "flex", gap: 16 }}>
                      <Button onClick={() => handleCloneClick(value)} disabled={loading1}>
                        duplicate
                      </Button>
                      <CloneCell value={`/events/new?clone=${value}`} />
                      <EditCell value={`/events/${value}`} />
                      {row.original.type?.includes("PARENT") ? (
                        <MDButton
                          variant="gradient"
                          color="secondary"
                          iconOnly
                          onClick={() => {
                            setParent(value);
                          }}
                        >
                          <BabyChangingStationIcon />
                        </MDButton>
                      ) : (
                        <MDButton
                          variant="gradient"
                          color="info"
                          iconOnly
                          onClick={() => setMerch(row.original)}
                        >
                          <PointOfSale />
                        </MDButton>
                      )}
                    </div>
                  ),
                },
              ],
              rows: data || [],
            }}
          />
        )}
      </Card>
    </>
  );
}

export default DataTables;
