import React from "react";
import MyModal from "components/Modal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useMutation } from "lib/hooks";
import { NotificationManager } from "react-notifications";

function DeleteModal({ open, onClose, onSuccess, children, uri }) {
  const { request, loading } = useMutation({
    uri,
    method: "delete",
  });

  return (
    <MyModal open={open} onClose={onClose} title="Are you sure delete ?">
      {children}
      <MDBox display="flex" justifyContent="flex-end" gap={2}>
        <MDButton color="secondary" onClick={onClose}>
          Cancel
        </MDButton>
        <MDButton
          color="warning"
          loading={loading}
          onClick={() => {
            request()
              .then((res) => {
                if (res?._id || res?.code === "deleteSuccess") {
                  return onSuccess(res);
                }
                onClose();
                NotificationManager.warning(res?.message || "error");
              })
              .catch((err) => {
                NotificationManager.warning(err?.message || err);
              });
          }}
        >
          Delete
        </MDButton>
      </MDBox>
    </MyModal>
  );
}

export default DeleteModal;
