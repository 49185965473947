import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "context/auth-provider";
import { useMutation } from "lib/hooks";
import Card from "@mui/material/Card";
import { NotificationManager } from "react-notifications";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function MFA() {
  const location = useLocation();
  const { username, session } = location.state;
  const [status, setStatus] = useState(null);
  const [otp, setOtp] = useState("");

  const { login } = useAuth();
  const { loading, request } = useMutation({ uri: "/auth/loginMFA" });
  return (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-3}
        p={3}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          MFA
        </MDTypography>
        <MDTypography display="block" variant="button" color="white" my={1}>
          Please input the code from your authenticator app
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDInput
          fullWidth
          label="Authentication code"
          value={otp}
          disabled={loading}
          variant="standard"
          error={status === "failed"}
          success={status === "success"}
          placeholder="123456"
          InputLabelProps={{ shrink: true }}
          onChange={(val) => {
            setOtp(val.target.value);
            if (val.target.value?.length === 6) {
              request({ username, session, userCode: val.target.value })
                .then((res) => {
                  if (res) {
                    setStatus("success");
                    login(res);
                  }
                  setStatus("failed");
                })
                .catch((err) => {
                  setStatus("failed");
                  console.error(err);
                  return NotificationManager.warning(err?.message || err);
                });
            }
          }}
        />
      </MDBox>
    </Card>
  );
}

export default MFA;
