import { useEffect } from "react";

export const useDebounce = (fn, delay, value) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      fn(value);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [value]);
};
