import React, { useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import { useQuery } from "../../lib/hooks";
import Loader from "../../components/Loader";
import MDBox from "../../components/MDBox";
import EventAutocomplete from "../ticket-templates/EventAutocomplete";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import { initialEvent } from "../../constant";

function Tickets() {
  const [event, setEvent] = useState(initialEvent);
  const [q, setQ] = useState("");

  const { loading, data, fetchData } = useQuery({
    uri: `/ticket/${initialEvent._id}`,
    manual: true,
  });
  useEffect(() => {
    if (event?._id) fetchData(`/ticket/${event?._id}`);
  }, [event]);

  const totalSold = useMemo(
    () =>
      data?.reduce((acc, cur) => {
        acc += cur.totalValue;
        return acc;
      }, 0),
    [loading]
  );
  const regex = new RegExp(q, "i"); // 'i' flag makes the search case-insensitive

  const tickets = useMemo(() => {
    if (data)
      return data
        ?.filter((d) => regex.test(d.username) || regex.test(d.email))
        .sort((a, b) => {
          if (a.username < b.username) {
            return -1;
          }
          if (a.username > b.username) {
            return 1;
          }
          return 0;
        });
    return [];
  }, [q, loading]);

  return (
    <div>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Card p={3}>
            <MDBox p={4}>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={5}
                flexWrap="wrap"
              >
                <MDBox display="flex" alignItems="center" gap={4} flex="1" flexWrap="wrap" mb={3}>
                  <div style={{ width: "100%", flex: "0 0 250px" }}>
                    <EventAutocomplete value={event} onChange={setEvent} />
                  </div>
                  <MDInput
                    value={q}
                    fullWidth
                    sx={{ width: "230px" }}
                    onChange={(e) => setQ(e.target.value)}
                    placeholder="Search by username or email"
                  />
                </MDBox>
                <MDTypography>
                  Total sold : <strong>{totalSold} ш</strong>
                </MDTypography>
              </MDBox>
              <div
                style={{
                  display: "grid",
                  gap: 16,
                  gridTemplateColumns: "repeat(auto-fill, minmax(200px,1fr))",
                }}
              >
                {tickets.map((ticket) => (
                  <Card key={ticket.email}>
                    <MDBox p={2}>
                      <MDTypography whiteSpace="nowrap">
                        {ticket.username} ( {ticket.totalValue} )
                      </MDTypography>
                      <div>
                        <MDTypography whiteSpace="nowrap" variant="caption">
                          {ticket.email}
                        </MDTypography>
                      </div>
                    </MDBox>
                  </Card>
                ))}
              </div>
            </MDBox>
          </Card>
        </>
      )}
    </div>
  );
}

export default Tickets;
