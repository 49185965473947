import React, { useEffect } from "react";
import MyModal from "components/Modal";
import Form from "rc-field-form";
import Grid from "@mui/material/Grid";
import { Input } from "@mui/material";
import FormItem from "components/FormItem";
import MDBox from "components/MDBox";
import ImageUpload from "components/ImageUpload";
import MDButton from "components/MDButton";
import { fieldRule } from "lib/utils/validations";
import { NotificationManager } from "react-notifications";
import { useMutation } from "lib/hooks";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";

function AttributeModal({ open, onClose, attr, templateId, refetch }) {
  const [form] = Form.useForm();

  const { request, loading: saving } = useMutation({
    uri: attr?.isNew
      ? `/merch-template/${templateId}/attribute`
      : `/merch-template/${templateId}/attribute-options`,
    method: !attr?.isNew ? "put" : "post",
  });

  const { request: upload, loading: uploading } = useMutation({ uri: "/s3-image-upload" });

  useEffect(() => {
    if (attr) {
      form.setFieldsValue(attr);
    }
  }, [attr]);

  return (
    <MyModal open={open} onClose={onClose} title="Attribute">
      <Form
        form={form}
        initialValues={attr}
        onFinish={(values) => {
          const options = values.options.reduce((acc, cur) => {
            const { isNew, ...rest } = cur;
            acc.push(rest);
            return acc;
          }, []);
          request({ ...values, options })
            .then((res) => {
              if (res.code === "updateSuccess" || res.code === "addedSuccess") {
                NotificationManager.success("Saved");
                refetch();
                onClose();
              } else {
                NotificationManager.warning(res?.message || "Error");
              }
            })
            .catch((e) => {
              console.error(e);
            });
        }}
      >
        <Grid container spacing={2} mb={2}>
          <Grid item md={6}>
            <FormItem name="name" rules={[{ required: true }]}>
              <MDInput
                fullWidth
                disabled={!attr?.isNew}
                label="Name"
                variant="standard"
                placeholder="Name"
                InputLabelProps={{ shrink: true }}
              />
            </FormItem>
          </Grid>
          <Grid item md={6}>
            <FormItem name="name_en" rules={[{ required: true }, fieldRule]}>
              <MDInput
                fullWidth
                label="Name en"
                disabled={!attr?.isNew}
                variant="standard"
                placeholder="Name english"
                InputLabelProps={{ shrink: true }}
              />
            </FormItem>
          </Grid>
        </Grid>
        <FormItem name="options">
          <Options upload={upload} uploading={uploading} />
        </FormItem>
        <MDBox display="flex" justifyContent="flex-end">
          <MDButton variant="contained" color="info" type="submit" loading={uploading || saving}>
            save
          </MDButton>
        </MDBox>
      </Form>
    </MyModal>
  );
}

export default AttributeModal;

function Options({ value, onChange, upload, uploading }) {
  const name = Form.useWatch("name_en");

  const validator = (_, val) => {
    const vals = value.filter((v) => v.value === val);
    if (value && vals?.length > 1) {
      return Promise.reject("Option must be unique");
    }
    return Promise.resolve();
  };
  return (
    <MDBox mb={3}>
      <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
        {value.map((val, idx) => (
          <Grid container key={idx} spacing={2} mb={2}>
            <Grid item md={5}>
              <FormItem name={["options", idx, "img"]}>
                <ImageUpload request={upload} loading={uploading} />
              </FormItem>
            </Grid>
            <Grid item md={6}>
              <FormItem
                name={["options", idx, "value"]}
                rules={[{ required: true }, { validator }]}
              >
                <Input
                  fullWidth
                  label="Value"
                  variant="standard"
                  placeholder="200"
                  // disabled={!val?.isNew}
                  InputLabelProps={{ shrink: true }}
                  type={name === "color" ? "color" : "text"}
                />
              </FormItem>
            </Grid>
            <Grid item md={1}>
              <MDButton
                iconOnly
                variant="contained"
                color="warning"
                onClick={() => {
                  const dto = value;
                  dto.splice(idx, 1);
                  onChange(dto);
                }}
              >
                <Icon>delete</Icon>
              </MDButton>
            </Grid>
          </Grid>
        ))}
      </div>
      <MDBox gap={2} display="flex" justifyContent="flex-end">
        <MDButton
          variant="contained"
          onClick={() => onChange([...value, { value: "", isNew: true }])}
        >
          add option
        </MDButton>
      </MDBox>
    </MDBox>
  );
}
