import React from "react";
import { InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDInput from "../../../components/MDInput";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import FormItem from "../../../components/FormItem";

function SeeSoldCount({ value, onChange, name }) {
  return (
    <div>
      <InputLabel sx={{ marginBottom: 4, fontSize: 20 }}>
        Usernames who can see the sold count
      </InputLabel>
      <Grid container spacing={3} mb={3}>
        {value?.map((v, idx) => (
          <Grid item xs={12} md={4} xl={3} key={idx}>
            <MDBox display="flex" alignItems="flex-start" justifyContent="center" gap={3}>
              <FormItem name={[name, idx]} rules={[{ required: true }]}>
                <MDInput
                  fullWidth
                  variant="standard"
                  placeholder="discodisco"
                  InputLabelProps={{ shrink: true }}
                />
              </FormItem>
              <MDButton
                iconOnly
                color="warning"
                variant="contained"
                onClick={() => {
                  const dto = value;
                  dto.splice(idx, 1);
                  onChange(dto);
                }}
              >
                <Icon>delete</Icon>
              </MDButton>
            </MDBox>
          </Grid>
        ))}
      </Grid>
      <MDBox display="flex" alignItems="flex-start" justifyContent="flex-start" gap={3}>
        <MDButton color="secondary" variant="contained" onClick={() => onChange([...value, ""])}>
          <Icon>add</Icon>
          Add
        </MDButton>
      </MDBox>
    </div>
  );
}

export default SeeSoldCount;
