import React from "react";
import { Field } from "rc-field-form";
import MDTypography from "./MDTypography";

function FormItem({ children, ...rest }) {
  return (
    <Field {...rest}>
      {(childProps, meta, form) => (
        <>
          {React.cloneElement(children, { ...childProps, error: !!meta?.errors?.length, form })}
          {meta?.errors?.map((error) => (
            <MDTypography color="error" variant="caption" lineHeight="1" key={error}>
              {error}
            </MDTypography>
          ))}
        </>
      )}
    </Field>
  );
}

export default FormItem;
