import React, { useState } from "react";
import MDButton from "components/MDButton";
import MyModal from "components/Modal";
import FormItem from "components/FormItem";

import Form from "rc-field-form";
import JSZip from "jszip";

import { QRCodeCanvas } from "qrcode.react";
import ticketQr from "assets/images/ticket-qr.png";
import NumberInput from "../../../components/NumberInput";
import MDBox from "../../../components/MDBox";
import { useMutation } from "../../../lib/hooks";
import { useAuth } from "../../../context/auth-provider";
import { ADMIN_USER } from "../../../constant";

function InvitationGenerate({ ticketTemplateId }) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { request, loading } = useMutation({ uri: `/invitation/create/${ticketTemplateId}` });
  const { user } = useAuth();
  const [qrData, setQrData] = useState([]);

  const canvasToImageData = (canvas) => canvas.toDataURL("image/png");

  const downloadCanvasesAsZip = (canvases) => {
    const zip = new JSZip();

    canvases.forEach((canvas, index) => {
      const imageData = canvasToImageData(canvas);
      const filename = `canvas_${index + 1}.png`; // Generate unique filenames
      zip.file(filename, imageData.split(",")[1], { base64: true });
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(content);
      link.download = "canvas_images.zip";
      setIsLoading(false);
      link.click();
    });
  };

  const downloadQrCodes = () => {
    const canvas = document.getElementsByTagName("canvas");

    const canvases = [];
    Array.from(canvas).forEach((cnva, idx) => {
      const qrCode = cnva; // Existing QRCode canvas
      const padding = 40; // Adjust padding as needed

      // Create a temporary canvas with desired size
      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");
      const tempWidth = qrCode.width + padding * 2;
      const tempHeight = qrCode.height + padding * 2;
      tempCanvas.width = tempWidth;
      tempCanvas.height = tempHeight;

      // Fill the temporary canvas with background color (optional)
      tempCtx.fillStyle = "#fff"; // Adjust background color as needed
      tempCtx.fillRect(0, 0, tempWidth, tempHeight);

      // Draw the QRCode onto the temporary canvas with padding
      tempCtx.drawImage(qrCode, padding, padding);

      // Use toDataURL() to get the dataURL of the temporary canvas
      canvases.push(tempCanvas);
    });
    downloadCanvasesAsZip(canvases);
  };

  if (!ticketTemplateId || !ADMIN_USER.includes(user.username)) return null;
  return (
    <div>
      <MDButton variant="gradient" color="warning" size="large" onClick={() => setOpen(true)}>
        Generate Invitation
      </MDButton>
      <MyModal open={open} onClose={() => setOpen(false)} title="Generate Invitations">
        <Form
          initialValues={{ count: 0 }}
          onFinish={({ count }) => {
            setIsLoading(true);
            request({ seats: count }, `/invitation/create/${ticketTemplateId}`, {}, false)
              .then((res) => {
                if (res?.length) {
                  setQrData(res.map((r) => r.qrText));
                  setTimeout(() => {
                    downloadQrCodes();
                  }, [3000]);
                }
              })
              .catch(console.log);
          }}
        >
          <FormItem name="count" rules={[{ required: true }]}>
            <NumberInput label="Count" placeholder="1200" />
          </FormItem>
          <div style={{ display: "none" }}>
            {qrData?.map((qr) => (
              <QRCodeCanvas
                key={qr}
                value={`ticket__${qr}`}
                size={320}
                level="L"
                bgColor="#ffffff"
                fgColor="#000000"
                imageSettings={{
                  src: ticketQr,
                  x: undefined,
                  y: undefined,
                  height: 40,
                  width: 40,
                  excavate: true,
                }}
              />
            ))}
          </div>
          <MDBox mt={3} display="flex" justifyContent="flex-end" gap={2}>
            <MDButton
              color="secondary"
              variant="gradient"
              disabled={loading || isLoading}
              onClick={() => setOpen(false)}
            >
              Cancel
            </MDButton>
            <MDButton
              type="submit"
              variant="gradient"
              color="info"
              loading={loading || isLoading}
              disabled={loading || isLoading}
            >
              Generate
            </MDButton>
          </MDBox>
        </Form>
      </MyModal>
    </div>
  );
}

export default InvitationGenerate;
