import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useParams } from "react-router-dom";
import { useQuery } from "../../../lib/hooks";
import MDTypography from "../../../components/MDTypography";
import Loader from "../../../components/Loader";

function SeatPicker({ selected, setSelected, eventId, hallPlan }) {
  const [loading, setLoading] = useState(true);
  const [stage, setStage] = useState("");
  const { id: templateId } = useParams();
  const { data, loading: fetching } = useQuery({ uri: `/event/${eventId}` });
  const taken =
    data?.ticketTemplates
      ?.filter((tmp) => tmp._id !== templateId)
      ?.reduce((acc, cur) => {
        acc.push(...cur.seatIds);
        return acc;
      }, []) || [];

  const onClick = (element) => {
    const id = element.getAttribute("id");
    const cls = element.getAttribute("selected");

    if (taken.indexOf(id) === -1) {
      if (cls === "1") {
        setSelected((prev) => prev.filter((s) => s !== id));
        element.setAttribute("selected", "0");
      } else {
        setSelected((prev) => [...prev, id]);
        element.setAttribute("selected", "1");
      }
    }
  };

  const init = () => {
    const elements = document.getElementsByClassName("seat");
    Array.from(elements).forEach((element) => {
      const id = element.getAttribute("id");

      if (id) {
        if (selected.indexOf(id) !== -1) {
          element.setAttribute("selected", "1");
        }
        if (taken.indexOf(id) !== -1) {
          element.setAttribute("taken", "1");
        } else {
          element.addEventListener("click", () => onClick(element));
        }
      }
    });
  };

  useEffect(() => {
    if (hallPlan) {
      setLoading(true);
      fetch(hallPlan)
        .then((res) => res.text())
        .then((res) => {
          setStage(res);
          setLoading(false);
        })
        .catch(console.log);
    }
  }, []);

  useEffect(() => {
    if (stage && data) init();
  }, [stage, data]);

  return (
    <SeatPickerStyle>
      <MDTypography>Select seats</MDTypography>
      <TransformWrapper
        initialScale={1}
        centerZoomedOut={false}
        doubleClick={{ mode: "reset" }}
        maxScale={20}
      >
        {() => (
          <>
            <TransformComponent>
              {loading ? <Loader /> : <div dangerouslySetInnerHTML={{ __html: stage }} />}
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </SeatPickerStyle>
  );
}

export default SeatPicker;

const SeatPickerStyle = styled.div`
  width: 800px;
  height: 800px;
  padding: 20px;
  border-radius: 24px;
  border: 1px solid #222;
  background-color: #090909;
  svg {
    width: 100%;
    height: 100%;
  }
  .seat {
    cursor: pointer;
    position: relative;
    fill: rgba(255, 255, 255, 0.5);
    &.box-0 {
      fill: #f5daf8;
    }
    &.box-1 {
      fill: #efb0fe;
    }
    &.box-2 {
      fill: #abb3fc;
    }
    &.box-3 {
      fill: #5df7a4;
    }
    &.box-4 {
      fill: #2ad0ca;
    }
  }
  .seat[selected="1"],
  .seat[selected="1"]:hover {
    stroke: none;
    fill: #e1f664;
    &.box-0[selected="1"],
    &.box-1[selected="1"],
    &.box-2[selected="1"],
    &.box-3[selected="1"],
    &.box-4[selected="1"] {
      stroke: none;
      fill: #e1f664;
    }
  }
  .seat[taken="1"],
  .seat[taken="1"]:hover {
    fill: #090909;
  }
`;
