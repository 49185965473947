const currencies = { mnt: "₮" };

export function formatMoney(val, currency = "mnt") {
  if (!val) return "";
  return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "’")} ${
    currencies[currency] || currency
  }`;
}

export function decodeSearchParams(searchParams) {
  return [...searchParams.entries()].reduce((acc, [key, val]) => {
    try {
      return {
        ...acc,
        [key]: JSON.parse(val),
      };
    } catch {
      return {
        ...acc,
        [key]: val,
      };
    }
  }, {});
}
