import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import dayjs from "dayjs";
import Form from "rc-field-form";
import { NotificationManager } from "react-notifications";
import MyModal from "../../components/Modal";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import { useMutation, useQuery } from "../../lib/hooks";
import FormItem from "../../components/FormItem";
import MDInput from "../../components/MDInput";
import NumberInput from "../../components/NumberInput";
import MDAvatar from "../../components/MDAvatar";

function EbarimtMerchantModal({ open, onClose, event }) {
  const { data, loading, fetchData } = useQuery({
    uri: `/ebarimt-merchants/${event?._id}`,
    manual: true,
  });

  const { request, loading: saving } = useMutation({ uri: "/ebarimt-merchants" });
  const [form] = Form.useForm();

  useEffect(() => {
    if (event) fetchData(`/ebarimt-merchants/${event._id}`);
  }, [event]);

  useEffect(() => {
    const dto = data?.merchants?.reduce((acc, cur) => {
      acc.push({ ...cur, factor: parseInt(Math.floor(cur.factor * 100), 10) });
      return acc;
    }, []) || [{ merchantTin: "", factor: 0 }];
    form.setFieldsValue({ merchants: dto });
  }, [data, loading]);

  const merchants = Form.useWatch("merchants", form);
  return (
    <MyModal open={open} onClose={onClose} title="Event merchants">
      <Form
        form={form}
        onFinish={(values) => {
          const merch = values?.merchants.reduce((acc, cur) => {
            acc.push({ ...cur, factor: cur.factor / 100 });
            return acc;
          }, []);
          request(
            { eventId: event?._id, merchants: merch },
            data?._id ? `/ebarimt-merchants/${data._id}` : "/ebarimt-merchants",
            {},
            true,
            data?._id ? "put" : "post"
          ).then((res) => {
            NotificationManager.success(res?.message || "Saved ");
            if (res?._id || res?.modifiedCount) {
              onClose();
            }
          });
        }}
      >
        <MDBox mb={3}>
          {event && (
            <Card
              sx={{
                gap: 5,
                padding: "20px",
                marginBottom: "20px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <MDAvatar size="xl" src={event.cover_image_v} variant="rounded" />
              <div style={{ flex: "1" }}>
                <MDTypography>{event.name}</MDTypography>
                <MDTypography fontSize="14px" color="light">
                  {dayjs(event.startDate).format("YYYY.MM.DD HH:mm")}
                </MDTypography>
              </div>
            </Card>
          )}
          <FormItem
            name="merchants"
            rules={[
              () => ({
                validator(_, value) {
                  const sum = value?.reduce((acc, cur) => acc + cur.factor, 0);

                  if (sum === 100) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Merchant factor нийлбэр 100 тэй тэнцүү байх ёстой");
                },
              }),
            ]}
          >
            <MerchantsField />
          </FormItem>
        </MDBox>
        <MDBox display="flex" alignItems="center" justifyContent="space-between">
          <MDButton
            variant="contained"
            color="secondary"
            onClick={() => {
              form.setFieldValue("merchants", [
                ...(merchants || []),
                { merchantTin: "", factor: 0 },
              ]);
            }}
          >
            <Icon>add</Icon> Add
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            type="submit"
            loading={saving}
            disabled={loading || saving}
          >
            Save
          </MDButton>
        </MDBox>
      </Form>
    </MyModal>
  );
}

export default EbarimtMerchantModal;

function MerchantsField({ value, onChange }) {
  console.log(">>>>>>> merchants >>>>>", value);

  return (
    <>
      <div>
        {value?.map((_, idx) => (
          <MDBox display="flex" gap={2} mb={idx + 1 < value?.length ? 3 : 0} key={idx}>
            <MDBox flex="1">
              <FormItem name={["merchants", idx, "merchantTin"]} rules={[{ required: true }]}>
                <MDInput
                  fullWidth
                  label="Merchant Tin"
                  variant="standard"
                  placeholder="Event name"
                  InputLabelProps={{ shrink: true }}
                />
              </FormItem>
            </MDBox>
            <MDBox flex="1">
              <FormItem
                name={["merchants", idx, "factor"]}
                rules={[
                  () => ({
                    // eslint-disable-next-line no-shadow
                    validator(_, factor) {
                      if (factor && factor >= 1 && factor <= 100) {
                        return Promise.resolve();
                      }
                      return Promise.reject("1 100н хооронд байх ёстой");
                    },
                  }),
                ]}
              >
                <NumberInput label="Merchant factor" placeholder="20" step={10} />
              </FormItem>
            </MDBox>
            <MDButton
              iconOnly
              variant="contained"
              color="warning"
              onClick={() => {
                const dto = value;
                dto.splice(idx, 1);
                onChange(dto);
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </MDBox>
        ))}
      </div>
    </>
  );
}
