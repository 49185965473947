import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { InputLabel } from "@mui/material";

function RichText({ value, onChange, label }) {
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
  ];
  return (
    <>
      <InputLabel mb={3}>{label}</InputLabel>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        style={{ color: "white", marginTop: "8px" }}
        formats={formats}
        modules={modules}
      />
    </>
  );
}

export default RichText;
