import React, { useState } from "react";
import Form from "rc-field-form";
import { useMutation } from "../../lib/hooks";
import FormItem from "../../components/FormItem";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

function Index() {
  const [res, setRes] = useState({});
  const { request, loading } = useMutation({ uri: "/" });
  return (
    <div>
      <Form
        onFinish={({ ticketId }) => {
          request({}, `/refund/ticket/${ticketId}`, {}, false).then(setRes);
        }}
      >
        <MDBox display="flex" gap={4} alignItems="flex-start" mb={4}>
          <div style={{ width: 400 }}>
            <FormItem name="ticketId" rules={[{ required: true }]}>
              <MDInput
                fullWidth
                label="Ticket Id"
                placeholder="Please"
                InputLabelProps={{ shrink: true }}
              />
            </FormItem>
          </div>
          <MDButton type="submit" loading={loading}>
            Refund
          </MDButton>
        </MDBox>
        <MDBox display="flex" gap={4} alignItem="flex-start" mb={4}>
          <div style={{ width: 400 }}>
            <MDTypography>Result</MDTypography>
          </div>
          <div>{JSON.stringify(res, null, 2)}</div>
        </MDBox>
      </Form>
    </div>
  );
}

export default Index;
