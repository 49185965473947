// @mui material components
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

function CoverLayout({ children }) {
  return (
    <PageLayout>
      <MDBox display="flex" alignItems="center" justifyContent="center" minHeight="100vh">
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default CoverLayout;
