import React, { useState, useMemo } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useQuery, useDebounce } from "lib/hooks";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import dayjs from "dayjs";

function EventAutocomplete({ value, onChange, disabled }) {
  const [open, setOpen] = useState(false);
  const [q, setQ] = useState(value?.name || "");

  const { fetchData: searchEvent, data } = useQuery({
    manual: true,
    uri: `/event/filter`,
    params: { name: q || value?.name || "", fetch: 18 },
  });

  const handleSearch = (val) => searchEvent(`/event/filter`, { name: val, fetch: 18 });

  useDebounce(handleSearch, 1200, q);

  const selected = useMemo(() => data?.find((d) => d?._id === value) || value, [value]);

  const options =
    data?.map((d) => ({
      _id: d?._id,
      name: d.name,
      startDate: d.startDate,
      location: d?.location,
      cover_image_v: d.cover_image_v,
    })) || [];

  return (
    <Autocomplete
      open={open}
      value={selected || ""}
      options={options}
      id="highlights-demo"
      onClose={() => setOpen(false)}
      getOptionLabel={(option) => option.name || ""}
      onChange={(_, option) => onChange(option)}
      renderInput={(params) => {
        if (selected && !open)
          return (
            <MDBox
              display="flex"
              alignItems="center"
              py={1}
              mb={1}
              position="relative"
              onClick={() => {
                if (!disabled) {
                  setOpen(!open);
                }
              }}
            >
              <MDInput
                {...params}
                sx={{ position: "absolute", inset: "0", opacity: "0 !important;" }}
              />
              <MDBox mr={2}>
                <MDAvatar
                  src={selected.cover_image_v}
                  alt="something here"
                  shadow="md"
                  variant="square"
                />
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
              >
                <MDTypography variant="button" fontWeight="medium">
                  {selected?.name}
                </MDTypography>
                <MDTypography variant="caption" color="text">
                  {selected?.location?.name}{" "}
                  {dayjs(selected?.startDate).format("YYYY-MM-DD, HH:mm")}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
        return (
          <MDInput
            {...params}
            fullWidth
            value={q}
            size="md"
            autoFocus={open}
            onFocus={() => setOpen(true)}
            onBlur={() => setOpen(false)}
            placeholder="Event search"
            onChange={(e) => {
              setQ(e.target.value);
              // useDebounce(e.target.value, 1200, handleSearch);
            }}
          />
        );
      }}
      renderOption={(props, option) => (
        <MDBox
          component="li"
          {...props}
          display="flex"
          alignItems="center"
          py={1}
          mb={1}
          onClick={() => {
            setOpen(false);
            onChange(option);
          }}
        >
          <MDBox mr={2}>
            <MDAvatar
              src={option.cover_image_v}
              alt="something here"
              shadow="md"
              variant="square"
            />
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MDTypography variant="button" fontWeight="medium">
              {option?.name}
            </MDTypography>
            <MDTypography variant="caption" color="text">
              {option?.location?.name} {dayjs(option?.startDate).format("YYYY-MM-DD, HH:mm")}
            </MDTypography>
          </MDBox>
        </MDBox>
      )}
    />
  );
}

export default EventAutocomplete;
