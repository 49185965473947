import React, { useMemo, useRef } from "react";
import { Marker, MapContainer, TileLayer } from "react-leaflet";

const center = {
  lat: 47.918474016983645,
  lng: 106.91767930984498,
};
function LatLon({ value, onChange }) {
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          onChange?.({
            ...value,
            latitude: marker.getLatLng().lat,
            longitude: marker.getLatLng().lng,
          });
        }
      },
    }),
    []
  );

  return (
    <MapContainer
      zoom={18}
      center={{
        lat: value?.latitude || center.lat,
        lng: value?.longitude || center.lng,
      }}
      style={{ width: "100%", height: 400 }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker
        eventHandlers={eventHandlers}
        position={{
          lat: value?.latitude || center.lat,
          lng: value?.longitude || center.lng,
        }}
        ref={markerRef}
        draggable
      />
    </MapContainer>
  );
}

export default LatLon;
