/**
 =========================================================
 * Otis Admin PRO - v2.0.1
 =========================================================

 * Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

import DataTable from "examples/Tables/DataTable";

// Data
import { Link } from "react-router-dom";
import React from "react";

import MDButton from "components/MDButton";
import { useQuery } from "lib/hooks";

import Loader from "components/Loader";
import DefaultCell from "examples/Tables/DefaultCell";
import MDAvatar from "components/MDAvatar";
import { NotificationManager } from "react-notifications";
import DeleteCell from "../../examples/Tables/DeleteCell";

function Validators() {
  const { data, loading, fetchData } = useQuery({ uri: "/validator/get/validators" });

  return (
    <>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDButton variant="gradient" color="info" component={Link} to="/validators/new">
          new validator
        </MDButton>
      </MDBox>
      <Card>
        {loading ? (
          <Loader />
        ) : (
          <DataTable
            canSearch
            entriesPerPage={false}
            table={{
              columns: [
                {
                  Header: "Username",
                  accessor: "userId.username",
                  Cell: ({ value, row }) => <DefaultCell value={value} />,
                },
                {
                  Header: "Email",
                  accessor: "userId.email",
                  Cell: ({ value }) => <DefaultCell value={value} />,
                },
                {
                  Header: "Events",
                  accessor: "events",
                  Cell: ({ value }) => (
                    <MDBox sx={{ display: "flex", flexDirection: "row", gap: 2, flexWrap: "wrap" }}>
                      {value.map((ev) => (
                        <MDAvatar
                          size="xl"
                          alt={ev.name}
                          variant="rounded"
                          src={ev.cover_image_v || ev.cover_image}
                        />
                      ))}
                    </MDBox>
                  ),
                },
                {
                  sorted: "none",
                  accessor: "_id",
                  Header: "Action",
                  Cell: ({ value }) => (
                    <DeleteCell
                      uri={`/validator/delete/${value}`}
                      onSuccess={(res) => {
                        NotificationManager.warning("Deleted");
                        fetchData();
                      }}
                    />
                  ),
                },
              ],
              rows: data || [],
            }}
          />
        )}
      </Card>
    </>
  );
}

export default Validators;
