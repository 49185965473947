// [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ ` = + -
export const passwordRules = [
  {
    pattern:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]:;<>,.?/~"'|\\`])[A-Za-z\d!@#$%^&*()_\-+={}[\]:;<>,.?/~"'|\\`]{12,}$/,
    message:
      "Password must be contain lowercase letter, uppercase letter,number,special character, minimum length 12",
  },
];

export const fieldRule = {
  pattern: /^[a-z]+$/,
  message: "Field must be contain a-z lowercase",
};
