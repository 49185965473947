import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import Cookies from "js-cookie";

import api from "lib/axios";
import { useMutation } from "lib/hooks/useMutation";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { ADMIN_USER } from "../constant";

function parseJwt(token) {
  if (!token) {
    return "";
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  // eslint-disable-next-line consistent-return
  return JSON.parse(window.atob(base64));
}

const AuthContext = createContext({ isAuth: false });

export function AuthProvider({ children }) {
  const [access, setAccess] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const { loading: loadingRefresh, request } = useMutation({ uri: "/auth/loginWithRefreshToken" });
  const { loading: loadingLogout, request: requestLogout } = useMutation({ uri: "/auth/logout" });
  const login = (data, isRefresh) => {
    if (data.accessToken) {
      const usr = parseJwt(data.accessToken);
      if (ADMIN_USER.includes(usr.username)) {
        Cookies.set("idToken", data.idToken, { expires: 1 });
        Cookies.set("access", data.accessToken, { expires: new Date(usr.exp * 1000) });
        if (data.refreshToken) {
          Cookies.set("token", data.refreshToken, { expires: 1 });
        }
        api.defaults.headers.Authorization = `Bearer ${data.accessToken}`;

        setUser(usr);
        setAccess(data.accessToken);
        if (!isRefresh) {
          NotificationManager.success("Signed in");
          navigate("/events");
        }
        if (location.pathname.includes("/auth")) {
          navigate("/events");
        }
      } else {
        NotificationManager.warning("Permission denied");
      }
    }
  };

  const loadUserFromCookies = () => {
    const refreshToken = Cookies.get("token");
    const idToken = Cookies.get("idToken");

    if (refreshToken) {
      api.defaults.headers.Authorization = `Bearer ${refreshToken}`;
      request({ idToken, refreshToken })
        .then((res) => login(res, true))
        .catch((e) => console.error(e));
    }
    setLoading(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setLoading(true);
      loadUserFromCookies();
    }, 3000000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    loadUserFromCookies();
  }, []);

  const logout = () =>
    requestLogout({ accessToken: access }).then(() => {
      navigate("/auth");
      setUser(null);
      setAccess(null);
      Cookies.remove("token");
      Cookies.remove("idToken");
      Cookies.remove("access");
      delete api.defaults.headers.Authorization;
      NotificationManager.success("Signed out");
    });

  const memoValue = useMemo(
    () => ({
      login,
      logout,
      isAuth: !!user,
      user,
      loading: loading || loadingRefresh || loadingLogout,
      access,
    }),
    [user, loading, loadingRefresh]
  );

  return <AuthContext.Provider value={memoValue}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);
