import { Link, useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";

import Form from "rc-field-form";

import { useMutation } from "lib/hooks";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import FormItem from "components/FormItem";
import { NotificationManager } from "react-notifications";
import { useAuth } from "../../context/auth-provider";

function Cover() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { loading, request } = useMutation({ uri: "/auth/login" });
  return (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-3}
        p={3}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Sign in
        </MDTypography>
        <MDTypography display="block" variant="button" color="white" my={1}>
          Enter your email and password to Sign In
        </MDTypography>
      </MDBox>
      <Form
        form={form}
        onFinish={(values) => {
          request(values)
            .then((res) => {
              if (res?.accessToken) {
                return login(res);
              }
              if (res?.ChallengeParameters?.USER_ID_FOR_SRP && res?.Session) {
                NotificationManager.success("2FA идэвхжсэн байна");
                return navigate(`/auth/mfa`, {
                  state: {
                    session: res.Session,
                    username: res?.ChallengeParameters?.USER_ID_FOR_SRP,
                  },
                });
              }
            })
            .catch((err) => {
              NotificationManager.warning(err?.message || "Error");
              form.setFields([
                { name: "password", value: "", errors: ["Имэйл эсвэл нууц үг буруу байна"] },
              ]);
            });
        }}
      >
        <MDBox pt={4} pb={3} px={3}>
          <MDBox>
            <MDBox mb={2}>
              <FormItem
                name={["email"]}
                rules={[
                  { required: true, message: "Email required" },
                  { type: "email", message: "Incorrect email" },
                ]}
              >
                <MDInput
                  fullWidth
                  type="email"
                  label="Email"
                  variant="standard"
                  placeholder="john@example.com"
                  InputLabelProps={{ shrink: true }}
                />
              </FormItem>
            </MDBox>
            <MDBox mb={2}>
              <FormItem
                name={["password"]}
                rules={[{ required: true, message: "Password required" }]}
              >
                <MDInput
                  type="password"
                  label="Password"
                  variant="standard"
                  fullWidth
                  placeholder="************"
                  InputLabelProps={{ shrink: true }}
                />
              </FormItem>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit" loading={loading}>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography
                component={Link}
                to="/auth/forgot"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Forgot password?
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Form>
    </Card>
  );
}

export default Cover;
