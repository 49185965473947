import React from "react";
import ImageUpload from "components/ImageUpload";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

function Images({ value, onChange, loading, request }) {
  return (
    <MDBox mb={3}>
      <MDBox
        mb={2}
        sx={{
          gap: "24px",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px,1fr))",
        }}
      >
        {value?.map((img, idx) => (
          <div key={idx}>
            <ImageUpload
              value={img}
              onChange={(url) => {
                const dto = value;
                dto.splice(idx, 1, url);
                onChange(dto);
              }}
              loading={loading}
              request={request}
              onRemove={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const dto = value;
                dto.splice(idx, 1);
                onChange(dto);
              }}
            />
          </div>
        ))}
      </MDBox>
      <MDButton
        type="button"
        color="dark"
        variant="contained"
        style={{ fontSize: 24 }}
        onClick={() => {
          onChange([...(value || []), ""]);
        }}
      >
        ADD IMAGE
      </MDButton>
    </MDBox>
  );
}

export default Images;
