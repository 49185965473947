import React from "react";
import { NotificationManager } from "react-notifications";
import { useMutation, useQuery } from "lib/hooks";
import MyModal from "components/Modal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import RenderOption from "./RenderOption";
import { getKeyFromName } from "../../../../lib/utils/merchandise";

function SellMerchItemModal({ open, onClose, item, onSuccess }) {
  const { loading, fetchData } = useQuery({
    uri: `/merch-template/${item?._id}/add-one`,
    manual: true,
  });

  return (
    <MyModal open={open} onClose={onClose} title="Sell item ?">
      <MDBox display="flex" flexWrap="wrap" gap={1} alignItems="center">
        {Object.entries(item?.attrs || {})?.map(([key, value]) => (
          <RenderOption value={value} isColor={getKeyFromName(key) === "color"} />
        ))}
      </MDBox>
      <MDBox display="flex" justifyContent="flex-end" gap={2}>
        <MDButton color="secondary" onClick={onClose} disabled={loading}>
          Cancel
        </MDButton>
        <MDButton
          color="success"
          loading={loading}
          onClick={() => {
            fetchData(`/merch-template/${item?._id}/add-one`)
              .then((res) => {
                if (res.code === "success") {
                  onSuccess(res);
                  return NotificationManager.success(res?.message || "error");
                }
                onClose();
                NotificationManager.warning(res?.message || "error");
              })
              .catch((err) => {
                NotificationManager.warning(err.message || err);
              });
          }}
        >
          Sell
        </MDButton>
      </MDBox>
    </MyModal>
  );
}

export default SellMerchItemModal;
