import React from "react";
import { InputLabel, TextareaAutosize } from "@mui/material";

function TextareaAuto({ label, ...rest }) {
  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <TextareaAutosize
        minRows={3}
        {...rest}
        style={{
          display: "block",
          width: "100%",
          padding: "16px 0 8px",
          borderLeft: "none",
          borderRight: "none",
          borderTop: "none",
          color: "#ffffffcc",
          outline: "none",
          background: "transparent",
          borderBottom: "1px solid #ffffffcc",
        }}
      />
    </div>
  );
}

export default TextareaAuto;
