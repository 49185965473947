import React from "react";
import MDBox from "./MDBox";
import LoadSvg from "./LoadSvg";

function Loader() {
  return (
    <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      <LoadSvg />
    </MDBox>
  );
}

export default Loader;
