/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Otis Admin PRO React themes
import theme from "assets/theme";

// Otis Admin PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Otis Admin PRO React contexts
import { useMaterialUIController } from "context";

import { NotificationContainer } from "react-notifications";
import Pages from "./pages";
import { AuthProvider } from "./context/auth-provider";
import "react-notifications/lib/notifications.css";

export default function App() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <NotificationContainer />

      <AuthProvider>
        <Pages />
      </AuthProvider>
    </ThemeProvider>
  );
}
