const paymentMethods = [
  {
    code: "qpay",
    name: "qPay wallet",
    logo: "https://s3.qpay.mn/p/e9bbdc69-3544-4c2f-aff0-4c292bc094f6/launcher-icon-ios.jpg",
  },
  {
    code: "socialpay",
    name: "Голомт SocialPay",
    logo: "https://qpay.mn/q/logo/socialpay.png",
  },
  {
    code: "hipay",
    name: "HiPay хэтэвч",
    logo: "https://cdn.ticketx.mn/uploads/3812bff7-edea-4e65-9556-3904746a6dc1.jpeg",
  },
  {
    code: "monpay",
    name: "Monpay",
    logo: "https://qpay.mn/q/logo/monpay.png",
  },
  {
    code: "mcredit",
    name: "M Credit",
    logo: "https://cdn.ticketx.mn/uploads/345b9600-0432-4843-a1a2-c022ad080227.jpeg",
  },
  {
    code: "visa",
    name: "Visa card Golomt",
    logo: "https://cdn.ticketx.mn/uploads/4fe1344d-249d-4274-97b9-726d30802862.png",
  },
  {
    code: "tdbm",
    name: "TDB card",
    logo: "https://qpay.mn/q/logo/tdbbank.png",
  },
  {
    code: "simple",
    name: "Simple",
    description: "Simple pay",
    logo: "https://cdn.ticketx.mn/uploads/98152d4c-54ab-4901-b2ab-d9f52ff6311d.jpeg",
  },
];

export default paymentMethods;
