import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { NotificationManager } from "react-notifications";
import AddOrEditModal from "./AddOrEditModal";
import DeleteModal from "./DeleteModal";

function Collaboraters({ value, onChange, prefix, form }) {
  const [active, setActive] = useState(null);
  const [remove, setRemove] = useState(null);

  return (
    <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
      {value?.map((sponsor, idx) => (
        <MDBox
          key={sponsor._id || idx}
          component="li"
          display="flex"
          alignItems="center"
          py={1}
          mb={1}
        >
          <MDBox mr={2}>
            <MDAvatar src={sponsor?.userId?.profile_image} alt="something here" shadow="md" />
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MDTypography variant="button" fontWeight="medium">
              {sponsor?.userId?.display_name || sponsor?.userId?.username}
            </MDTypography>
            <MDTypography variant="caption" color="text">
              {sponsor?.role}
            </MDTypography>
          </MDBox>
          <MDBox ml="auto" display="flex" gap={1}>
            <MDButton variant="contained" color="info" iconOnly onClick={() => setActive(idx)}>
              <Icon>edit</Icon>
            </MDButton>
            <MDButton
              iconOnly
              color="warning"
              variant="contained"
              onClick={() => {
                if (sponsor?._id) {
                  setRemove(sponsor);
                } else {
                  const dto = value;
                  dto.splice(idx, 1);
                  onChange(dto);
                }
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </MDBox>
        </MDBox>
      ))}
      <MDButton
        variant="contained"
        color="secondary"
        onClick={() => {
          setActive(value?.length || 0);
        }}
      >
        <Icon>add</Icon> Add
      </MDButton>
      <DeleteModal
        form={form}
        active={remove}
        open={!!remove}
        isSponsor={prefix !== "collaboratedBy"}
        onSuccess={(res) => {
          const dto = value.filter((val) => val?._id !== remove?._id);
          onChange(dto);
          setRemove(null);
          NotificationManager.success(res?.message || "Deleted");
        }}
        onClose={() => setRemove(null)}
      />
      {active != null && (
        <AddOrEditModal
          open
          form={form}
          index={active}
          prefix={prefix}
          onClose={() => setActive(null)}
          title={active !== value?.length ? "Edit" : "Add"}
        />
      )}
    </MDBox>
  );
}

export default Collaboraters;
