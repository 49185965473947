import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

function Index() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/validate");
  }, []);

  return (
    <>
      <Link to="/validate">Validate</Link>
    </>
  );
}

export default Index;
