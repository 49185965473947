import React from "react";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormItem from "components/FormItem";
import UserSelect from "pages/events/[id]/UserSelect";

function Artists({ value, onChange }) {
  return (
    <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
      {value?.map((sponsor, idx) => (
        <MDBox
          gap={3}
          key={sponsor._id || idx}
          component="li"
          display="flex"
          alignItems="center"
          py={1}
          mb={1}
        >
          <MDBox flex="1">
            <FormItem name={["artists", idx]} rules={[{ required: true }]}>
              <UserSelect />
            </FormItem>
          </MDBox>
          <MDBox ml="auto" display="flex" gap={1}>
            <MDButton
              iconOnly
              color="warning"
              variant="contained"
              onClick={() => {
                const dto = value;
                dto.splice(idx, 1);
                onChange(dto);
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </MDBox>
        </MDBox>
      ))}
      <MDButton
        variant="contained"
        color="secondary"
        onClick={() => {
          onChange([...(value || []), { username: "" }]);
        }}
      >
        <Icon>add</Icon> Add
      </MDButton>
    </MDBox>
  );
}

export default Artists;
