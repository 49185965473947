import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import dayjs from "dayjs";
import PointOfSale from "@mui/icons-material/PointOfSale";
import MyModal from "../../components/Modal";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import { useQuery } from "../../lib/hooks";
import LoadSvg from "../../components/LoadSvg";

function ChildModal({ open, onClose, parent, setMerch }) {
  const { data, loading, fetchData } = useQuery({
    uri: `/event/${parent}/children`,
    manual: true,
  });

  useEffect(() => {
    if (parent) fetchData(`/event/${parent}/children`);
  }, [parent]);

  // By khebe
  const {
    data: data1,
    fetchData: duplicate,
    loading: loading1,
  } = useQuery({ uri: `/event/1/duplicate`, manual: true });

  // TODO: munkunu
  const handleCloneClick = async (value) => {
    duplicate(`/event/${value}/duplicate`).then(() => {
      fetchData(`/event/${parent}/children`);
    });
  };

  return (
    <MyModal open={open} onClose={onClose} title="Child events">
      {loading && <LoadSvg />}
      {data?.map((child) => (
        <Card sx={{ marginBottom: 2 }}>
          <MDBox display="flex" alignItems="center" p={1}>
            <MDTypography variant="button" fontWeight="medium">
              {dayjs(child.startDate).format("YYYY-MM-DD HH:mm")}
            </MDTypography>
            {child.name.toLowerCase().includes("copy") ? (
              <MDTypography variant="button" fontWeight="medium">
                (Copy)
              </MDTypography>
            ) : null}
            <MDBox ml="auto" display="flex" gap={1}>
              <MDButton onClick={() => handleCloneClick(child._id)} disabled={loading1}>
                duplicate
              </MDButton>
              <MDButton variant="gradient" color="info" iconOnly onClick={() => setMerch(child)}>
                <PointOfSale />
              </MDButton>
              <MDButton
                iconOnly
                color="info"
                component={Link}
                variant="contained"
                to={`/events/${child._id}`}
              >
                <Icon>edit</Icon>
              </MDButton>
              <MDButton
                iconOnly
                variant="contained"
                component={Link}
                to={`/events/new?parentId=${parent}&clone=${child._id}`}
              >
                <Icon>copy</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      ))}
      <MDButton
        component={Link}
        variant="contained"
        color="secondary"
        to={`/events/new?parentId=${parent}&clone=${parent}`}
      >
        <Icon>add</Icon> Add
      </MDButton>
    </MyModal>
  );
}

export default ChildModal;
