import React from "react";
import MDInput from "./MDInput";

function NumberInput({ value, onChange, ...rest }) {
  return (
    <MDInput
      fullWidth
      variant="standard"
      InputLabelProps={{ shrink: true }}
      {...rest}
      type="number"
      value={value}
      onChange={(e) => onChange?.(parseInt(e.target.value, 10))}
    />
  );
}

export default NumberInput;
