import React from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import TextareaAuto from "../../../components/TextareaAuto";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import FormItem from "../../../components/FormItem";
import MDInput from "../../../components/MDInput";

function Descriptions({ value, onChange }) {
  return (
    <Grid container spacing={3} mb={3}>
      <Grid item xs={12} md={6}>
        <MDTypography variant="h5">Descriptions</MDTypography>
      </Grid>
      <Grid item xs={12} md={6} />
      {value?.map((val, idx) => (
        <React.Fragment key={idx}>
          <Grid item xs={12} md={4}>
            <FormItem name={["descriptions", idx, "title"]}>
              <MDInput
                fullWidth
                label="Title"
                variant="standard"
                placeholder="Title"
                InputLabelProps={{ shrink: true }}
              />
            </FormItem>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormItem name={["descriptions", idx, "description"]}>
              <TextareaAuto label="Description" />
            </FormItem>
          </Grid>
        </React.Fragment>
      ))}
      <Grid item md={6}>
        <MDButton
          variant="contained"
          color="secondary"
          onClick={() => onChange([...(value || []), { title: "", description: "" }])}
        >
          <Icon>add</Icon> Add
        </MDButton>
      </Grid>
    </Grid>
  );
}

export default Descriptions;
