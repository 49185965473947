import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "lib/hooks";
import MDBox from "components/MDBox";
import Loader from "components/Loader";
import { Tab, Tabs } from "@mui/material";
import TemplateForm from "./TemplateForm";
import Variants from "./Variants";

function Index() {
  const { id } = useParams();
  const isNew = id === "new";
  const [tab, setTab] = useState("template");

  const {
    data,
    loading: fetching,
    fetchData,
  } = useQuery({ uri: `/merch-template/${id}`, manual: isNew });

  useEffect(() => {
    setTab("template");
  }, [id]);

  return (
    <>
      {fetching ? (
        <Loader />
      ) : (
        <>
          <MDBox sx={{ borderBottom: 1, borderColor: "divider" }} mb={3}>
            <Tabs aria-label="basic tabs example" value={tab} onChange={(e, t) => setTab(t)}>
              <Tab label="Merchant template" value="template" />
              <Tab label="Merchant items" value="variants" disabled={isNew} />
            </Tabs>
          </MDBox>
          <div hidden={tab !== "template"}>
            <TemplateForm fetching={fetching} data={data} isNew={isNew} id={id} />
          </div>
          <div hidden={tab !== "variants"}>
            <Variants
              id={id}
              data={data}
              fetching={fetching}
              refetch={() => fetchData(`/merch-template/${id}`)}
            />
          </div>
        </>
      )}
    </>
  );
}

export default Index;
