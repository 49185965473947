import React from "react";
import Icon from "@mui/material/Icon";
import { useSearchParams } from "react-router-dom";
import MDPagination from "../../../components/MDPagination";

function getPagination(currentPage, totalPages) {
  const pagesToShow = 4;
  const halfPagesToShow = Math.floor(pagesToShow / 2);

  let startPage = currentPage - halfPagesToShow;
  let endPage = currentPage + halfPagesToShow;

  if (startPage <= 0) {
    endPage -= startPage - 1;
    startPage = 1;
  }

  if (endPage > totalPages) {
    endPage = totalPages;
    if (endPage - pagesToShow + 1 > 0) {
      startPage = endPage - pagesToShow + 1;
    } else {
      startPage = 1;
    }
  }

  const pagination = [];

  // eslint-disable-next-line no-plusplus
  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  return pagination;
}

function Pagination({ totalCount, limit }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page") || "1", 10);
  const total = Math.ceil(totalCount / limit);

  const gotoPage = (p) => {
    setSearchParams({
      ...searchParams,
      page: p,
    });
  };

  const renderPagination = getPagination(page, total).map((option) => (
    <MDPagination
      item
      key={option}
      active={page === option}
      onClick={() => gotoPage(Number(option))}
    >
      {option}
    </MDPagination>
  ));

  const canPreviousPage = page > 1;
  const canNextPage = page < total;

  return (
    <MDPagination variant="gradient" color="info">
      {canPreviousPage && (
        <MDPagination item onClick={() => gotoPage(page - 1)}>
          <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
        </MDPagination>
      )}
      {renderPagination}
      {canNextPage && (
        <MDPagination item onClick={() => gotoPage(page + 1)}>
          <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
        </MDPagination>
      )}
    </MDPagination>
  );
}

export default Pagination;
