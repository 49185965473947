import React, { memo } from "react";
import { useSearchParams } from "react-router-dom";
import { decodeSearchParams } from "lib/utils/helper";
import Grid from "@mui/material/Grid";
import { debounce } from "@mui/material";
import MDBox from "../../components/MDBox";
import MDInput from "../../components/MDInput";
import EventAutocomplete from "./EventAutocomplete";

function SearchSection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchDto = decodeSearchParams(searchParams);

  return (
    <MDBox p={2}>
      <Grid container spacing={3}>
        <Grid item sm={3}>
          <EventAutocomplete
            value={searchDto?.eventId}
            onChange={(option) => {
              setSearchParams({ ...searchDto, eventId: option?._id || "" });
            }}
          />
        </Grid>
        <Grid item sm={3}>
          <MDInput
            fullWidth
            placeholder="Search template by description"
            onChange={(e) => {
              setSearchParams({ ...searchDto, search: e.target.value });
            }}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default memo(SearchSection);
