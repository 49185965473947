"use client";

import React from "react";
import styled from "styled-components";

export function LineItem({ image, qty, name, type }) {
  return (
    <LineItemStyle className="template">
      <img src={image} width={70} alt="" className="image" />
      <div>
        <div className="count">
          Quantity: <strong>{qty}</strong>
        </div>
        <div className="name">{name}</div>
        {type && <div className="type">{type}</div>}
      </div>
    </LineItemStyle>
  );
}

const LineItemStyle = styled.div`
  gap: 16px;
  display: flex;
  padding: 10px;
  align-items: center;
  font-family: var(--font-okta);
  border-radius: 18px;
  background: #171717;
  border: 1px solid #202020;
  margin: 8px 0 24px;
  .image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  .count {
    color: #929292;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    strong {
      color: #fff;
      font-size: 16px;
    }
  }
  .name {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .type {
    display: flex;
    align-items: center;
    color: #e1e1e1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
`;
