import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Card from "@mui/material/Card";
import dayjs from "dayjs";
import EditCell from "examples/Tables/EditCell";
import { useQuery } from "lib/hooks";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import MDAvatar from "components/MDAvatar";
import DefaultCell from "examples/Tables/DefaultCell";
import { decodeSearchParams, formatMoney } from "lib/utils/helper";
import SearchSection from "./SearchSection";
import Pagination from "../../examples/Tables/DataTable/Pagination";

function TicketTempaltes() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchDto = decodeSearchParams(searchParams);
  const { data, loading, fetchData } = useQuery({
    uri: "/ticket-template",
    params: { page: 1, limit: 20 },
    manual: true,
  });

  useEffect(() => {
    if (!loading) {
      fetchData("/ticket-template", { ...searchDto, limit: 20 });
    }
  }, [searchParams]);

  const { ticketTemplates = [], totalCount } = data || {};

  return (
    <>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDButton variant="gradient" color="info" component={Link} to="/ticket-templates/new">
          new ticket template
        </MDButton>
      </MDBox>
      <Card>
        <DataTable
          loading={loading}
          entriesPerPage={false}
          searchComp={<SearchSection />}
          paginationComp={<Pagination totalCount={totalCount} limit={5} />}
          table={{
            columns: [
              {
                Header: "Event cover",
                accessor: "eventId.cover_image_v",
                Cell: ({ value, row }) => (
                  <MDAvatar
                    size="xl"
                    src={value}
                    variant="rounded"
                    alt={row?.original?.name || "ticketx"}
                  />
                ),
              },
              {
                Header: "Event name",
                accessor: "eventId.name",
                Cell: ({ value }) => <DefaultCell value={value} />,
              },
              {
                Header: "Name",
                accessor: "name",
                Cell: ({ value }) => <DefaultCell value={value} />,
              },
              {
                Header: "Description",
                accessor: "description",
                Cell: ({ value }) => <DefaultCell value={value} />,
              },
              {
                Header: "Sell price",
                accessor: "sellPrice",
                Cell: ({ value }) => (
                  <DefaultCell value={formatMoney(value?.amount, value?.currency)} />
                ),
              },
              {
                Header: "Start date",
                accessor: "startDate",
                Cell: ({ value }) => (
                  <DefaultCell value={dayjs(value).format("YYYY-MM-DD HH:mm")} />
                ),
              },
              {
                sorted: "none",
                accessor: "_id",
                Header: "Action",
                Cell: ({ value }) => <EditCell value={`/ticket-templates/${value}`} />,
              },
            ],
            rows: ticketTemplates || [],
          }}
        />
      </Card>
    </>
  );
}

export default TicketTempaltes;
