import { Link } from "react-router-dom";

import Card from "@mui/material/Card";

import Form from "rc-field-form";

import { useMutation, useQuery } from "lib/hooks";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import FormItem from "components/FormItem";
import { NotificationManager } from "react-notifications";
import { useState } from "react";
import { passwordRules } from "lib/utils/validations";
import { useAuth } from "context/auth-provider";

function Cover() {
  const { login } = useAuth();
  const [form] = Form.useForm();
  const [email, setEmail] = useState();
  const { loading, request } = useMutation({ uri: "/auth/login" });
  const { loading: loadingEmail, fetchData } = useQuery({ uri: "/auth/login", manual: true });

  return (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-3}
        p={3}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Forgot password
        </MDTypography>
        <MDTypography display="block" variant="button" color="white" my={1}>
          <div
            dangerouslySetInnerHTML={{
              __html: email ? `Please input the code sent to <b>${email}</b>` : "Enter your email",
            }}
          />
        </MDTypography>
      </MDBox>
      {!email ? (
        <Form
          onFinish={(values) => {
            fetchData(`/auth/password/reset/${values.email}`)
              .then((res) => {
                if (res?.CodeDeliveryDetails?.AttributeName === "email")
                  return setEmail(values.email);
                NotificationManager.warning("User not found");
              })
              .catch((err) => {
                NotificationManager.warning(err.message || "User not found");
              });
          }}
        >
          <MDBox pt={4} pb={3} px={3}>
            <MDBox>
              <MDBox mb={2}>
                <FormItem
                  name={["email"]}
                  rules={[
                    { required: true, message: "Email required" },
                    { type: "email", message: "Incorrect email" },
                  ]}
                >
                  <MDInput
                    fullWidth
                    type="email"
                    label="Email"
                    variant="standard"
                    placeholder="john@example.com"
                    InputLabelProps={{ shrink: true }}
                  />
                </FormItem>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="submit"
                  loading={loadingEmail}
                >
                  Reset password
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography
                  component={Link}
                  to="/auth"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Back to Login
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Form>
      ) : (
        <Form
          form={form}
          onFinish={({ newPassword, verificationCode }) => {
            request({ email, newPassword, verificationCode, password: newPassword })
              .then((res) => {
                if (res.accessToken) {
                  NotificationManager.success("Нууц үг амжилттай шинэчлэгдлээ");
                  login(res);
                }
              })
              .catch((err) => NotificationManager.warning(err?.message || err));
          }}
        >
          <MDBox pt={4} pb={3} px={3}>
            <MDBox>
              <MDBox mb={2}>
                <FormItem name={["newPassword"]} rules={passwordRules}>
                  <MDInput
                    type="password"
                    label="Password"
                    variant="standard"
                    fullWidth
                    placeholder="************"
                    InputLabelProps={{ shrink: true }}
                  />
                </FormItem>
              </MDBox>
              <MDBox mb={2}>
                <FormItem
                  name={["passwordConfirm"]}
                  rules={[
                    { required: true, message: "Нууц үгээ давтан оруулна уу" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Нууц үг зөрсөн байна");
                      },
                    }),
                  ]}
                >
                  <MDInput
                    type="password"
                    variant="standard"
                    label="Re-enter password"
                    fullWidth
                    placeholder="************"
                    InputLabelProps={{ shrink: true }}
                  />
                </FormItem>
              </MDBox>
              <MDBox mb={2}>
                <FormItem
                  name={["verificationCode"]}
                  rules={[{ required: true, message: "Code required" }]}
                >
                  <MDInput
                    label="Verification code"
                    variant="standard"
                    fullWidth
                    placeholder="************"
                    InputLabelProps={{ shrink: true }}
                  />
                </FormItem>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit" loading={loading}>
                  Reset password
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography
                  component={Link}
                  to="/auth"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Back to Login
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Form>
      )}
    </Card>
  );
}

export default Cover;
