import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "rc-field-form";
import { NotificationManager } from "react-notifications";

import Grid from "@mui/material/Grid";
import { MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormItem from "components/FormItem";
import MDInput from "components/MDInput";
import Checkbox from "components/CheckBox";
import Select from "components/Select";
import { useMutation, useQuery } from "lib/hooks";
import Loader from "components/Loader";
import Descriptions from "./Descriptions";
import ImageUpload from "../../../components/ImageUpload";

function Index() {
  const { username } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const isNew = username === "new";

  const { data, loading: fetching } = useQuery({
    uri: `/user/${username}`,
    manual: isNew,
  });
  const { request, loading: saving } = useMutation({
    uri: `/user/headles`,
    method: isNew ? "post" : "put",
  });
  const { request: upload, loading: uploading } = useMutation({ uri: "/s3-image-upload" });
  useEffect(() => {
    if (data?._id) {
      form.setFieldsValue(data);
    }
  }, [fetching]);

  return (
    <>
      {fetching ? (
        <Loader />
      ) : (
        <Form
          form={form}
          initialValues={data}
          onFinish={({ socials, username: valUsername, ...rest }) => {
            const dto = socials.filter((s) => s && !!s.link);
            const requestUrl = isNew ? `/user/headless` : `/user/headless/${username}`;
            request(
              { ...rest, username: isNew ? valUsername?.toLowerCase() : username, socials: dto },
              requestUrl
            )
              .then((res) => {
                NotificationManager.success(res?.message || "Saved ");
                navigate("/users");
              })
              .catch((er) => {
                NotificationManager.success(er?.message || er);
              });
          }}
        >
          <div>
            <MDBox p={0}>
              <Grid container spacing={3} mb={3} md={8}>
                {isNew && (
                  <Grid item xs={12} md={6}>
                    <FormItem name="username" rules={[{ required: true }]}>
                      <MDInput
                        fullWidth
                        label="Username"
                        variant="standard"
                        placeholder="Username"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormItem>
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <FormItem name={["display_name"]} rules={[{ required: true }]}>
                    <MDInput
                      fullWidth
                      label="Display name"
                      variant="standard"
                      placeholder="Display name"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormItem>
                </Grid>
                {isNew && (
                  <Grid item xs={12} md={6}>
                    <FormItem name={["email"]} rules={[{ required: true, type: "email" }]}>
                      <MDInput
                        fullWidth
                        label="Email"
                        variant="standard"
                        placeholder="test@mail.com"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormItem>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <FormItem name={["is_artist"]}>
                    <Checkbox label="Is Artist" />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormItem name="profile_image" rules={[{ required: true }]}>
                    <ImageUpload label="Profile image" request={upload} loading={uploading} />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormItem name={["cover_image"]} rules={[{ required: true }]}>
                    <ImageUpload label="Cover image" request={upload} loading={uploading} />
                  </FormItem>
                </Grid>
                {[0, 1, 2, 4].map((idx) => (
                  <React.Fragment key={idx}>
                    <Grid item xs={12} md={6}>
                      <FormItem name={["socials", idx, "type"]}>
                        <Select label="Social type">
                          <MenuItem value="youtube">Youtube</MenuItem>
                          <MenuItem value="facebook">Facebook</MenuItem>
                          <MenuItem value="instagram">Instagram</MenuItem>
                          <MenuItem value="web">Web</MenuItem>
                        </Select>
                      </FormItem>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormItem name={["socials", idx, "link"]}>
                        <MDInput
                          fullWidth
                          label="Social link"
                          variant="standard"
                          placeholder="Social link"
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormItem>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
              <MDBox mb={3}>
                <FormItem name="descriptions">
                  <Descriptions />
                </FormItem>
              </MDBox>

              <MDBox display="flex" justifyContent="flex-end">
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  size="large"
                  loading={saving}
                  disabled={uploading}
                >
                  Save
                </MDButton>
              </MDBox>
            </MDBox>
          </div>
        </Form>
      )}
    </>
  );
}

export default Index;
