import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "rc-field-form";
import { NotificationManager } from "react-notifications";
import Grid from "@mui/material/Grid";
import { InputLabel, MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import FormItem from "components/FormItem";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import Checkbox from "components/CheckBox";
import Select from "components/Select";
import { useMutation, useQuery } from "lib/hooks";
import EventAutocomplete from "../EventAutocomplete";
import Loader from "../../../components/Loader";
import TextareaAuto from "../../../components/TextareaAuto";
import MDButton from "../../../components/MDButton";
import NFTTemplate from "./NFTTemplate";
import SeatPicker from "./SeatPicker";
import InvitationGenerate from "./InvitationGenerate";
import SeatInvitationGenerate from "./SeatInvitationGenerate";
import SeeSoldCount from "./SeeSoldCount";
import AcceptablePayment from "./AcceptablePayment";
import HoldModal from "./HoldModal";

function Index() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const { id } = useParams();
  const [form] = Form.useForm();

  const {
    data,
    loading: fetching,
    fetchData,
  } = useQuery({
    uri: `/ticket-template/${id}`,
    manual: id === "new",
  });
  const { request, loading: saving } = useMutation({
    uri: `/event/${data?.eventId?._id}/ticket-template`,
    method: id === "new" ? "post" : "put",
  });
  useEffect(() => {
    if (data?._id) {
      form.setFieldsValue(data);
    }
  }, [fetching]);

  useEffect(() => {
    setSelected(data?.seatIds || []);
  }, [fetching]);

  const isSeatWatch = Form.useWatch("isSeat", form);
  const eventIdWatch = Form.useWatch("eventId", form);
  const typeWatch = Form.useWatch("type", form);

  return (
    <>
      {fetching ? (
        <Loader />
      ) : (
        <Form
          form={form}
          initialValues={data}
          onFinish={({ eventId, available, isSeat, ...values }) => {
            const requestUrl =
              id !== "new"
                ? `/event/ticket-template/${id}`
                : `/event/${eventId?._id}/ticket-template`;
            request(
              {
                ...values,
                isSeat,
                seatIds: selected || [],
                total: isSeat ? selected.length || values.total : values.total,
                ...(id !== "new"
                  ? {}
                  : { available: isSeat ? selected.length || values.total : values.total }),
              },
              requestUrl
            )
              .then((res) => {
                NotificationManager.success(res?.message || "Saved ");
                if (res?._id) navigate(`/ticket-templates/${res?._id}`);
              })
              .catch((er) => {
                NotificationManager.success(er?.message || er);
              });
          }}
        >
          <div>
            <MDBox p={0}>
              <Grid container spacing={3} mb={3} md={8}>
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name={["merchants", 0]}>
                    <Select
                      fullWidth
                      variant="standard"
                      size="medium"
                      label="Merchant"
                      sx={{ minHeight: "29px" }}
                    >
                      <MenuItem value="portal">Portal</MenuItem>
                      <MenuItem value="ubcomedy">Ubcomedy</MenuItem>
                    </Select>
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6} xl={4} />
                <Grid item xs={12} md={6} xl={4} />
                <Grid item xs={12} md={6}>
                  <FormItem name={["eventId"]} rules={[{ required: true }]}>
                    <EventSelect disabled={!!data?._id} />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormItem name="isHidden">
                    <Checkbox label="Is hidden" />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormItem name="name">
                    <MDInput
                      fullWidth
                      label="Name"
                      variant="standard"
                      placeholder="Template name"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormItem name="name_en">
                    <MDInput
                      fullWidth
                      label="Name en"
                      variant="standard"
                      placeholder="Template name"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormItem name="startDate" rules={[{ required: true }]}>
                    <MDDatePicker input={{ label: "Start date", name: "startDate" }} />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormItem name="endDate">
                    <MDDatePicker input={{ label: "End date", name: "endDate" }} />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Type</InputLabel>
                  <FormItem name="type" rules={[{ required: true }]}>
                    <Select fullWidth variant="standard" size="medium" sx={{ minHeight: "29px" }}>
                      <MenuItem value="standart">Standart</MenuItem>
                      <MenuItem value="vip">VIP</MenuItem>
                    </Select>
                  </FormItem>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormItem name="isSeat">
                    <Checkbox label="Have a seat" />
                  </FormItem>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormItem name="isHighDemand">
                    <Checkbox label="Is High Demand" />
                  </FormItem>
                </Grid>
                {!isSeatWatch ? (
                  <Grid item xs={12} md={6}>
                    <FormItem name="total" rules={[{ required: true }]}>
                      <MDInput
                        fullWidth
                        label="Ticket count"
                        variant="standard"
                        placeholder="200"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormItem>
                  </Grid>
                ) : (
                  <Grid item xs={12} md={6}>
                    <FormItem name="color" rules={[{ required: true }]}>
                      <MDInput
                        fullWidth
                        type="color"
                        variant="standard"
                        label="Ticket color"
                        placeholder="#656543"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormItem>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  {" "}
                  <FormItem name="seat_cnt">
                    <MDInput
                      fullWidth
                      label="Seat count"
                      variant="standard"
                      placeholder="0"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDBox display="flex" alignItems="center" gap={1}>
                    <FormItem name={["sellPrice", "amount"]} rules={[{ required: true }]}>
                      <MDInput
                        fullWidth
                        label="Sell price"
                        variant="standard"
                        placeholder="200"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormItem>
                    <div>
                      <InputLabel>Currency</InputLabel>
                      <FormItem name={["sellPrice", "currency"]} rules={[{ required: true }]}>
                        <Select
                          fullWidth
                          variant="standard"
                          size="medium"
                          sx={{ minHeight: "29px" }}
                        >
                          <MenuItem value="mnt">MNT</MenuItem>
                          <MenuItem value="usd">USD</MenuItem>
                        </Select>
                      </FormItem>
                    </div>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDBox display="flex" alignItems="center" gap={1}>
                    <FormItem name={["actualPrice", "amount"]}>
                      <MDInput
                        fullWidth
                        label="Actual price"
                        variant="standard"
                        placeholder="200"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormItem>
                    <div>
                      <InputLabel>Currency</InputLabel>
                      <FormItem name={["actualPrice", "currency"]}>
                        <Select
                          fullWidth
                          variant="standard"
                          size="medium"
                          sx={{ minHeight: "29px" }}
                        >
                          <MenuItem value="mnt">MNT</MenuItem>
                          <MenuItem value="usd">USD</MenuItem>
                        </Select>
                      </FormItem>
                    </div>
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox mb={3}>
                <FormItem name="description">
                  <TextareaAuto label="Description" />
                </FormItem>
              </MDBox>
              <MDBox mb={3}>
                <FormItem name="description_en">
                  <TextareaAuto label="Description en" />
                </FormItem>
              </MDBox>
              <MDBox display="flex" gap={5} mb={4}>
                <MDBox flex={1}>
                  {eventIdWatch?._id &&
                    eventIdWatch?.location?.hall_plan &&
                    typeWatch === "vip" &&
                    isSeatWatch && (
                      <MDBox mb={3}>
                        <SeatPicker
                          selected={selected}
                          setSelected={setSelected}
                          eventId={eventIdWatch?._id}
                          hallPlan={eventIdWatch?.location?.hall_plan}
                        />
                      </MDBox>
                    )}
                  {!isSeatWatch && (
                    <MDBox mb={3}>
                      <FormItem name="hideSoldNumber">
                        <SeeSoldCount name="hideSoldNumber" />
                      </FormItem>
                    </MDBox>
                  )}
                </MDBox>
                <MDBox flex={1}>
                  <InputLabel sx={{ marginBottom: 4, fontSize: 20 }}>
                    Acceptable payment method
                  </InputLabel>
                  <FormItem name="acceptablePaymentMethods">
                    <AcceptablePayment />
                  </FormItem>
                </MDBox>
              </MDBox>
              <MDBox display="flex" justifyContent="flex-end" gap={2}>
                {eventIdWatch?._id && (
                  <HoldModal
                    isSeat={isSeatWatch}
                    eventId={eventIdWatch?._id}
                    hallPlan={eventIdWatch?.location?.hall_plan}
                  />
                )}
                {isSeatWatch && <SeatInvitationGenerate ticketTemplateId={data?._id} />}
                <InvitationGenerate ticketTemplateId={data?._id} />
                <NFTTemplate
                  refetch={fetchData}
                  id={data?.nftTemplateId}
                  ticketTemplateId={data?._id}
                />
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  size="large"
                  loading={saving}
                >
                  Save
                </MDButton>
              </MDBox>
            </MDBox>
          </div>
        </Form>
      )}
    </>
  );
}

export default Index;

function EventSelect(props) {
  return <EventAutocomplete {...props} />;
}
