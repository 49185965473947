import React, { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import MyModal from "components/Modal";

import { QRCodeCanvas } from "qrcode.react";
import ticketQr from "assets/images/ticket-qr.png";
import dayjs from "dayjs";
import { isArray } from "lodash";
import MDBox from "../../../components/MDBox";
import { useMutation } from "../../../lib/hooks";
import { useAuth } from "../../../context/auth-provider";
import MDTypography from "../../../components/MDTypography";
import { downloadQrCodes, extractSeat } from "../../../lib/utils/canvas";
import { ADMIN_USER } from "../../../constant";

function SeatInvitationGenerate({ ticketTemplateId }) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const { user } = useAuth();

  const { request, loading } = useMutation({ uri: `/invitation/create/${ticketTemplateId}` });
  const fetchInvoices = () =>
    request({}, "/invitation/get/seatInvoice", {}, false).then((res) => {
      if (isArray(res)) {
        setInvoices(res);
      }
    });

  useEffect(() => {
    if (open) {
      fetchInvoices();
    }
  }, [open]);

  const [qrData, setQrData] = useState([]);

  if (!ticketTemplateId || !ADMIN_USER.includes(user.username)) return null;

  return (
    <div>
      <MDButton variant="gradient" color="warning" size="large" onClick={() => setOpen(true)}>
        Generate Seat Invitation
      </MDButton>
      <MyModal open={open} onClose={() => setOpen(false)} title="Generate Seat Invitations">
        <div style={{ display: "none" }}>
          {qrData?.map((qr) => (
            <QRCodeCanvas
              key={qr.qr}
              value={`ticket__${qr.qr}`}
              size={320}
              level="L"
              bgColor="#ffffff"
              fgColor="#000000"
              imageSettings={{
                src: ticketQr,
                x: undefined,
                y: undefined,
                height: 40,
                width: 40,
                excavate: true,
              }}
            />
          ))}
        </div>
        <div>
          {invoices?.length ? (
            <div>
              {invoices.map((invoice) => (
                <MDBox mb={2} p={2} border="1px solid" borderRadius={4}>
                  <MDTypography>
                    Invoice created at: {dayjs(invoice.createdAt).format("YYYY/MM/DD hh:mm:ss")}
                  </MDTypography>
                  <MDTypography>Invoice description: {invoice.invoiceDescription}</MDTypography>
                  <MDBox mb={1} display="flex" alignItems="flex-start" gap={4}>
                    <MDTypography>Seats: </MDTypography>
                    <div>
                      {invoice.templates.map(({ seats }) =>
                        seats.map((s) => (
                          <MDTypography color="secondary" textAlign="left">
                            {extractSeat(s)}
                          </MDTypography>
                        ))
                      )}
                    </div>
                  </MDBox>
                  <MDBox display="flex" justifyContent="flex-end">
                    <MDButton
                      sx={{}}
                      loading={loading || isLoading}
                      disabled={loading || isLoading}
                      onClick={() => {
                        setIsLoading(true);

                        request({}, `/invitation/create/seatInvoice/${invoice._id}`, {}, false)
                          .then((res) => {
                            if (res?.length) {
                              const qrdatas = res.map((r) => ({ qr: r.qrText, seatId: r.seatId }));
                              setQrData(qrdatas);

                              setTimeout(() => {
                                downloadQrCodes(qrdatas).then(setIsLoading(false));
                              }, [3000]);
                            }
                          })
                          .catch(console.log);
                      }}
                    >
                      Generate
                    </MDButton>
                  </MDBox>
                </MDBox>
              ))}
            </div>
          ) : (
            <MDTypography>Invoice not found</MDTypography>
          )}
        </div>

        <MDBox mt={3} display="flex" justifyContent="flex-end" gap={2}>
          <MDButton
            variant="gradient"
            color="info"
            loading={loading || isLoading}
            disabled={loading || isLoading}
            onClick={fetchInvoices}
          >
            Refresh
          </MDButton>
        </MDBox>
      </MyModal>
    </div>
  );
}

export default SeatInvitationGenerate;
