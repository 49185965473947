import JSZip from "jszip";

const labels = {
  s: "Суудал",
  R: "Эгнээ",
  S: "Сектор",
  T: "Ширээ",
  F: "Давхар",
};

export function extractSeat(val, isFilename = false) {
  return val.split("-").reduce((acc, cur) => {
    // @ts-ignore
    const [first, ...rest] = cur;
    if (labels[first]) {
      if (isFilename) {
        // @ts-ignore
        acc.push(`${labels[first]}-${rest.join("")}-`);
      } else {
        // @ts-ignore
        acc.push(`${labels[first]}: ${rest.join("")} `);
      }
    }
    return acc;
  }, []);
}
const canvasToImageData = (canvas) => canvas.toDataURL("image/png");

const downloadCanvasesAsZip = (canvases, dtos) => {
  const zip = new JSZip();
  return new Promise((resolve) => {
    canvases.forEach((canvas, index) => {
      const imageData = canvasToImageData(canvas);
      const filename = dtos[index]?.seatId
        ? `${extractSeat(dtos[index].seatId, true).join("").trim("-")}.png`
        : `canvas_${index}.png`;

      zip.file(filename, imageData.split(",")[1], { base64: true });
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(content);
      link.download = "canvas_images.zip";
      link.click();
      resolve();
    });
  });
};

export const downloadQrCodes = (dtos) => {
  const canvas = document.getElementsByTagName("canvas");

  const canvases = [];
  Array.from(canvas).forEach((cnva, idx) => {
    const qrCode = cnva; // Existing QRCode canvas
    const padding = 40; // Adjust padding as needed

    // Create a temporary canvas with desired size
    const tempCanvas = document.createElement("canvas");
    const tempCtx = tempCanvas.getContext("2d");
    const tempWidth = qrCode.width + padding * 2;
    const tempHeight = qrCode.height + padding * 2;
    tempCanvas.width = tempWidth;
    tempCanvas.height = tempHeight;

    // Fill the temporary canvas with background color (optional)
    tempCtx.fillStyle = "#fff"; // Adjust background color as needed
    tempCtx.fillRect(0, 0, tempWidth, tempHeight);

    // Draw the QRCode onto the temporary canvas with padding
    tempCtx.drawImage(qrCode, padding, padding);

    // Use toDataURL() to get the dataURL of the temporary canvas
    canvases.push(tempCanvas);
  });
  return downloadCanvasesAsZip(canvases, dtos);
};
