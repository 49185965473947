import React from "react";

function RenderOption({ value, isColor, label }) {
  if (isColor) {
    return (
      <span
        style={{
          width: 24,
          height: 24,
          background: value,
          display: "inline-block",
          borderRadius: "50%",
        }}
      />
    );
  }
  return (
    <div style={{ color: "white", border: "1px solid #424242", padding: "0 8px", borderRadius: 4 }}>
      {label ? `${label}: ` : ""} <strong>{value}</strong>
    </div>
  );
}

export default RenderOption;
