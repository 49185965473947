import React from "react";
import { Checkbox as CheckboxRoot, FormControlLabel } from "@mui/material";

function CheckBox({ label, onChange, ...rest }) {
  const checked = rest?.value === "true" || rest?.value === true;
  return (
    <FormControlLabel
      // eslint-disable-next-line
      label={<div >{label}</div>}
      control={<CheckboxRoot checked={checked} {...rest} onChange={() => onChange?.(!checked)} />}
    />
  );
}

export default CheckBox;
