import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import dayjs from "dayjs";
import EditCell from "examples/Tables/EditCell";
import { useQuery } from "lib/hooks";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import MDAvatar from "components/MDAvatar";
import DefaultCell from "examples/Tables/DefaultCell";
import { formatMoney } from "lib/utils/helper";

function MerchTemplates() {
  const { data, loading } = useQuery({ uri: "/merch-template" });

  return (
    <>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDButton variant="gradient" color="info" component={Link} to="/merchant/templates/new">
          new merchant template
        </MDButton>
      </MDBox>
      <Card>
        <DataTable
          loading={loading}
          entriesPerPage={false}
          table={{
            columns: [
              {
                Header: "Image",
                accessor: "imageUrl",
                Cell: ({ value, row }) => (
                  <MDAvatar
                    size="xl"
                    src={value[0]}
                    variant="rounded"
                    alt={row?.original?.name || "ticketx"}
                  />
                ),
              },
              {
                Header: "Name",
                accessor: "name",
                Cell: ({ value }) => <DefaultCell value={value} />,
              },
              {
                Header: "Event name",
                accessor: "eventId.name",
                Cell: ({ value }) => <DefaultCell value={value} />,
              },
              {
                Header: "Description",
                accessor: "description",
                Cell: ({ value }) => <DefaultCell value={value} />,
              },
              {
                Header: "Sell price",
                accessor: "sellPrice",
                Cell: ({ value }) => (
                  <DefaultCell value={formatMoney(value?.amount, value?.currency)} />
                ),
              },
              {
                Header: "Start date",
                accessor: "startDate",
                Cell: ({ value }) => (
                  <DefaultCell value={dayjs(value).format("YYYY-MM-DD HH:mm")} />
                ),
              },
              {
                sorted: "none",
                accessor: "_id",
                Header: "Action",
                Cell: ({ value }) => <EditCell value={`/merchant/templates/${value}`} />,
              },
            ],
            rows: data || [],
          }}
        />
      </Card>
    </>
  );
}

export default MerchTemplates;
