import { useState } from "react";

import axios from "../axios";

export const useMutation = ({ uri, method = "post" }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const request = (data, requestUrl, headers, haveVersion = true, methodReq) => {
    setLoading(true);
    return axios
      .request({
        url: haveVersion ? `/v1${requestUrl || uri}` : requestUrl || uri,
        data,
        method: methodReq || method,
        headers,
      })
      .then((res) => {
        setLoading(false);
        return res;
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
        return Promise.reject(e);
      });
  };

  return { loading, error, request };
};
