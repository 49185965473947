import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Logo from "../assets/images/logo.png";
import Sidenav from "../examples/Sidenav";
import { setMiniSidenav, useMaterialUIController } from "../context";
import MDBox from "../components/MDBox";
import { useAuth } from "../context/auth-provider";
import MDAvatar from "../components/MDAvatar";
import profilePicture from "../assets/images/team-3.jpg";
import LoadSvg from "../components/LoadSvg";
import DashboardNavbar from "../examples/Navbars/DashboardNavbar";

function DashboardLayout({ children }) {
  const navigate = useNavigate();
  const { isAuth, loading, user, logout } = useAuth();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  useEffect(() => {
    if (!isAuth && !loading) navigate("/auth");
  }, [loading]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  if (loading)
    return (
      <MDBox display="flex" alignItems="center" justifyContent="center" minHeight="100vh">
        <LoadSvg />
      </MDBox>
    );

  const menus = [
    {
      type: "collapse",
      name: user?.username,
      key: "brooklyn-alice",
      icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
      collapse: [
        {
          name: "Logout",
          key: "logout",
          onClick: logout,
        },
      ],
    },
    {
      key: "users",
      type: "title",
      route: "/users",
      name: "Users",
    },
    {
      key: "events",
      type: "title",
      route: "/events",
      name: "Events",
    },
    {
      type: "title",
      key: "ticket-templates",
      route: "/ticket-templates",
      name: "Ticket templates",
    },
    {
      type: "title",
      name: "Merchant templates",
      key: "merchant/templates",
      route: "/merchant/templates",
    },
    {
      type: "title",
      name: "Lounges",
      key: "lounges",
      route: "/lounges",
    },
    {
      type: "title",
      name: "Tickets",
      key: "tickets",
      route: "/tickets",
    },
    {
      type: "title",
      name: "NFT Collections",
      key: "nft-collections",
      route: "/nft-collections",
    },
    {
      type: "title",
      name: "Merches",
      key: "merches",
      route: "/merches",
    },
    {
      type: "collapse",
      name: "Validation",
      key: "Validation",
      icon: <QrCodeScannerIcon />,
      collapse: [
        {
          type: "title",
          name: "Validator",
          key: "validate",
          route: "/validate",
        },
        {
          type: "title",
          name: "Validators",
          key: "validators",
          route: "/validators",
        },
      ],
    },
    {
      type: "title",
      name: "Refund",
      key: "refund",
      route: "/refund",
    },
  ];

  return (
    <div>
      <Sidenav
        brand={Logo}
        routes={menus}
        color={sidenavColor}
        brandName="TicketX"
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 3,
          position: "relative",

          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        <DashboardNavbar />
        {children}
      </MDBox>
    </div>
  );
}

export default DashboardLayout;
