import React from "react";
import MyModal from "components/Modal";
import FormItem from "components/FormItem";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Form from "rc-field-form";
import { NotificationManager } from "react-notifications";
import { useMutation } from "lib/hooks";
import UserSelect from "./UserSelect";

function AddOrEditModal({ open, onClose, index, prefix, title, form }) {
  const roleField = [prefix, index, "role"];
  const userField = [prefix, index, "userId"];
  const value = Form.useWatch([prefix, index]);

  const { request, loading } = useMutation({
    uri: `/event/${prefix === "sponsoredBy" ? "sponsor" : "collaborator"}/${value?._id}`,
    method: "put",
  });

  return (
    <MyModal open={open} onClose={onClose} title={title}>
      <MDBox mb={3}>
        <FormItem name={userField} rules={[{ required: true }]}>
          <UserSelect disabled={!!value?._id} />
        </FormItem>
      </MDBox>
      <MDBox mb={3}>
        <FormItem name={roleField} rules={[{ required: true }]}>
          <MDInput fullWidth label="Role" variant="standard" InputLabelProps={{ shrink: true }} />
        </FormItem>
      </MDBox>
      <MDBox display="flex" justifyContent="flex-end">
        <MDButton
          loading={loading}
          onClick={() => {
            if (value?.role && value?.userId) {
              if (value?._id) {
                request({ userId: value.userId._id, role: value.role })
                  .then((res) => {
                    NotificationManager.success(res?.message || "Updated");
                    onClose();
                  })
                  .catch((e) => NotificationManager.warning(e?.message || e));
              } else {
                onClose();
              }
            } else {
              form.validateFields([roleField, userField]);
            }
          }}
          ml="auto"
          color="info"
        >
          Save
        </MDButton>
      </MDBox>
    </MyModal>
  );
}

export default AddOrEditModal;
