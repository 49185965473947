import React, { useState } from "react";
import { InputLabel, Select as SelectRoot, Input } from "@mui/material";

function Select({ children, label, value, onChange, error, ...rest }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <InputLabel>{label}</InputLabel>
      {open ? (
        <SelectRoot
          open
          fullWidth
          value={value}
          onChange={onChange}
          variant="standard"
          placeholder={value}
          sx={{ minHeight: "29px" }}
          onClose={() => setOpen(false)}
          {...rest}
        >
          {children}
        </SelectRoot>
      ) : (
        <Input value={value} fullWidth onFocus={() => setOpen(true)} />
      )}
    </>
  );
}

export default Select;
