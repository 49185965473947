import React from "react";
import { Card, Modal } from "@mui/material";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import MDTypography from "./MDTypography";

function MyModal({ title, children, open, onClose, ...rest }) {
  return (
    <Modal
      open={open}
      size="small"
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledCard className="modal-card" {...rest}>
        <div className="header">
          <MDTypography>{title}</MDTypography>
          <button type="button" className="close" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        {children}
      </StyledCard>
    </Modal>
  );
}

export default MyModal;

const StyledCard = styled(Card)`
  max-height: 90vh;
  overflow-y: auto;
  padding: 16px;
  min-width: 600px;
  max-width: 900px;
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  .header {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
    .close {
      color: #fff;
      position: absolute;
      top: -40px;
      right: -16px;
      background-color: transparent;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  @media all and (max-width: 992px) {
    min-width: 80vw;
    max-width: 90vw;
  }
`;
