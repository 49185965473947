import React from "react";
import MDButton from "components/MDButton";
import MyModal from "components/Modal";
import FormItem from "components/FormItem";
import MDInput from "components/MDInput";
import Form from "rc-field-form";
import { useMutation } from "lib/hooks/useMutation";
import Grid from "@mui/material/Grid";
import { MenuItem } from "@mui/material";
import Select from "../../../components/Select";

import MDBox from "../../../components/MDBox";
import TextareaAuto from "../../../components/TextareaAuto";
import ImageUpload from "../../../components/ImageUpload";

const initialFormValues = {
  image: "",
  description: "",
  type: "LIMITED_EDITION",
  blockchainType: "POLYGON",
};

const typeOptions = [
  { value: "LIMITED_EDITION", label: "LIMITED EDITION" },
  { value: "OPEN_EDITION", label: "OPEN EDITION" },
  { value: "AUCTION_EDITION", label: "AUCTION EDITION" },
  { value: "SINGLE_EDITION", label: "SINGLE EDITION" },
  { value: "EDUCATION_EDITION", label: "EDUCATION EDITION" },
  { value: "INVESTMENT_EDITION", label: "INVESTMENT EDITION" },
];

const blockchainOptions = [
  { value: "BSC", label: "BSC" },
  { value: "BSC_TESTNET", label: "BSC TESTNET" },
  { value: "ETH", label: "ETH" },
  { value: "HARMONY", label: "HARMONY" },
  { value: "POLYGON", label: "POLYGON" },
  { value: "MUMBAI", label: "POLYGON MUMBAI" },
];

function NFTTemplateModal({ open, onClose, refetch, ticketTemplateId }) {
  const [form] = Form.useForm();
  const { request, loading } = useMutation({ uri: `/mongolnft/nft-templates/${ticketTemplateId}` });
  const { request: upload, loading: uploading } = useMutation({ uri: "/s3-image-upload" });

  return (
    <MyModal open={open} onClose={onClose} title="Create NFT Template" sx={{ width: 750 }}>
      <Form
        form={form}
        initialValues={initialFormValues}
        onFinish={(values) => {
          request(values).then((res) => {
            if (res?._id) {
              refetch();
              onClose();
              form.resetFields();
            }
          });
        }}
      >
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} md={6}>
            <FormItem name="name" rules={[{ required: true }]}>
              <MDInput
                fullWidth
                label="Template name"
                placeholder="Template name"
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </FormItem>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormItem name="type" rules={[{ required: true }]}>
              <Select label="Type">
                {typeOptions.map((op) => (
                  <MenuItem value={op.value} key={op.value}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
            </FormItem>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormItem name="image" rules={[{ required: true }]}>
              <ImageUpload label="Image" request={upload} loading={uploading} />
            </FormItem>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormItem name="blockchainType" rules={[{ required: true }]}>
              <Select label="Blockchain Type">
                {blockchainOptions.map((op) => (
                  <MenuItem value={op.value} key={op.value}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
            </FormItem>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormItem name="external_url">
              <MDInput
                fullWidth
                label="External url"
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </FormItem>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormItem name="animation_url">
              <MDInput
                fullWidth
                variant="standard"
                label="Animation url"
                InputLabelProps={{ shrink: true }}
              />
            </FormItem>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormItem name="youtube_url">
              <MDInput
                fullWidth
                variant="standard"
                label="Youtube url"
                InputLabelProps={{ shrink: true }}
              />
            </FormItem>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormItem name="description" rules={[{ required: true }]}>
              <TextareaAuto label="Description" />
            </FormItem>
          </Grid>
        </Grid>
        <MDBox display="flex" justifyContent="flex-end" gap={2}>
          <MDButton color="secondary" variant="gradient" disabled={loading} onClick={onClose}>
            Cancel
          </MDButton>
          <MDButton type="submit" variant="gradient" color="info" loading={loading}>
            Save
          </MDButton>
        </MDBox>
      </Form>
    </MyModal>
  );
}

export default NFTTemplateModal;
