import styled from "styled-components";

export const ValidationStyle = styled.div`
  display: flex;
  justify-content: center;
  @media all and (max-width: 991px) {
    width: calc(100vw - 48px);
  }
  .container {
    margin: 0 auto;
    max-width: 280px;
    overflow: hidden;
    svg {
      path {
        stroke: #22f86be5;
      }
    }

    .content {
      width: 250px;
    }
  }

  .barContent {
    margin: 12px 0 32px;
    padding: 24px 0 12px;
    max-height: 40vh;
    overflow-y: auto;
    border-top: 1px dashed rgba(255, 255, 255, 0.2);
    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
    .item {
      gap: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 12px;
    }
    .qty {
      padding: 2px 12px;
      color: #000;
      border-radius: 4px;
      background: #fff;
      font-weight: 700;
    }
  }

  .attr {
    color: #929292;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 24px; /* 171.429% */
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    strong {
      color: #fff;
      font-size: 16px;
    }
  }
`;
