import axios from "axios";

// export const baseURL = "https://st-admin.ticketx.mn";
export const baseURL = process.env.REACT_APP_API_URI;

const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(async (config) => {
  const token = document.cookie.replace(/(?:(?:^|.*;\s*)access\s*=\s*([^;]*).*$)|^.*$/, "$1");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) =>
    // Do something with the response data
    response.data,
  (error) =>
    // Handle any error that occurs in the response
    Promise.reject(error?.response?.data ?? error)
);

export default api;
